import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import DropDownitems, { IMenus } from "../../widgets/DropDownItems";
import AssistoTextFiled from "../../widgets/AssistoTextField";
import {
  alphaNumericWithSpaceOnlySpecialCharPattern,
  alphabetsWithFiveThousandChar,
  alphabetsWithTwoThousandChar,
  alphanumericWithSpaceAndDotChar,
  digitsOnly,
  experienceFieldWithDecimalChar,
  onlineUrl,
  onlyAlphabetsWithMaxFifteenChar,
  onlyNumbersWithMaxFourDigits,
  mobileNumberPattern,
  validEmailPattern,
} from "../../utils/regExp";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { ThemeProvider } from "styled-components";
import theme from "../../widgets/Theme/theme";
import AssistoDatePicker from "../../widgets/AssistoDatePicker";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import AssistoCustomAddInputField from "../../widgets/AssistoCustomAddInputField";
import SchoolIcon from "@mui/icons-material/School";
import PaidIcon from "@mui/icons-material/Paid";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { AssistoButton } from "../../widgets/Button";
import ContactsIcon from "@mui/icons-material/Contacts";
import AssistantIcon from "@mui/icons-material/Assistant";
import AssistoTimePicker from "../../widgets/AssistoTimePicker";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import dayjs, { Dayjs } from "dayjs";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { ILocationInfoResponse } from "../../thunks/countryLocationThunk";
import { countryData } from "../../reducers/countryLocationReducer";
import {
  IStateInfoResponse,
  StateLocationToken,
} from "../../thunks/stateLocationThunk";
import { stateData } from "../../reducers/stateLocationReducer";
import {
  CityLocationToken,
  ICityInfoResponse,
} from "../../thunks/cityLocation";
import { cityData } from "../../reducers/cityLocationReducer";
import { IIndustryData } from "../../thunks/industryThunk";
import { industryData } from "../../reducers/industryReducer";
import { ICategoryData } from "../../thunks/categoryThunk";
import { categoryData } from "../../reducers/categoryReducer";
import { IRolesData, RolesThunkToken } from "../../thunks/rolesThunk";
import { RolesData } from "../../reducers/rolesReducer";
import { certifiedDegreeData } from "../../reducers/educationDegreeReducer";
import { IEducationData } from "../../thunks/educationDegreeThunk";
import {
  ISpecializationData,
  SpecializationThunk,
} from "../../thunks/specializationThunk";
import { SpecializationData } from "../../reducers/specializationReducer";
import { subskillData } from "../../reducers/getSubSkillReducer";
import { ISubSkillData } from "../../thunks/getSubSkillThunk";
import { IAddSkillRequest, callAddSkill } from "../../thunks/skillsThunk";
import { skillData } from "../../reducers/getSkillsReducer";
import { ISkillData } from "../../thunks/getSkillthunk";
import { currencyData } from "../../reducers/currencyReducer";
import { ICurrencyResponse } from "../../thunks/currencyThunk";
import { createJdThunk } from "../../thunks/createJdThunk";
import {
  createJdApiLoading,
  resetCreateJdState,
} from "../../reducers/createJdReducer";
import SnackBar, { Status, Variant } from "../../widgets/Toaster";
import { alphabetsNumbersWithDotAndHash } from "../../utils/regExp";
import { CONTRACT, FULL_TIME, WALK_IN_DRIVE } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { jobsPage } from "../Routes/routes";

export interface ISkills {
  skill: string;
  experience_required_for_skill: number;
  ismandatory: boolean;
  extras: Extras;
}

export interface Extras {}

interface Ifields {
  job_posting_type: string;
  domain: string;
  jobTitleField: string;
  companyName: string | null;
  status: string;
  industry: IIndustryData | null;
  category: ICategoryData | null;
  roles: IRolesData | null;
  job_end_date: Dayjs | null;
  vacancy: number | null;
  notice_period: string;
  jobSummery: string | null;
  jobDescription: string | null;
  fromExpField: number;
  toExpField: number;
  certifiedDegree: IEducationData | null;
  specialization: ISpecializationData | null;
  minSalary: string;
  maxSalary: string;
  currency: ICurrencyResponse | null;
  name: string;
  phoneNo: number | null;
  email: string;
  aboutCompany: string;
  onlineUrl: string | null;
  venue: string | null;
  country: ILocationInfoResponse | null;
  state: IStateInfoResponse | null;
  city: ICityInfoResponse | null;
  selectedSkills: ISkillData | null;
  fromTiming: Dayjs | null;
  toTiming: Dayjs | null;
  fromWalkDate: Dayjs | null;
  toWalkDate: Dayjs | null;
  joiningDate: Dayjs | null;
  contractTimeperiod: string;
  billingRate: string | null;
  mustHaveSkill: string[];
  goodToHaveSkill: string[];
  jd_skills_details: ISkills[] | null;
}

function JobDescription() {
  const { t } = useTranslation(NAMESPACE.CREATE_JD);
  const dispatch: AppDispatch = useDispatch();
  const [toaster, setToaster] = useState({
    open: false,
    message: "",
    status: Status.Success,
    variant: Variant.Filled,
  });
  const createJdApiLoadingStatus = useSelector((state: RootState) =>
    createJdApiLoading(state)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (createJdApiLoadingStatus === "succeeded") {
      setCreateJdPage(initialCreateJdState);
      setErrors(initialErrorJdState);
      setToaster((toast) => {
        return {
          ...toast,
          open: true,
          message: t("CREATE_JD.JOB_CREATED_SUCCESSFULLY"),
          status: Status.Success,
        };
      });
      dispatch(resetCreateJdState());
    }
    if (createJdApiLoadingStatus === "failed") {
      setToaster((toast) => {
        return {
          ...toast,
          open: true,
          message: t("CREATE_JD.FAILED_TO_CREATE_A_JD"),
          status: Status.Error,
        };
      });
    }
  }, [createJdApiLoadingStatus]);

  const initialCreateJdState = {
    job_posting_type: FULL_TIME,
    domain: "india",
    jobTitleField: "",
    companyName: "",
    status: "Active",
    industry: null,
    category: null,
    roles: null,
    job_end_date: null,
    vacancy: null,
    notice_period: "",
    jobSummery: null,
    jobDescription: null,
    fromExpField: 0,
    toExpField: 0,
    certifiedDegree: null,
    specialization: null,
    minSalary: "",
    maxSalary: "",
    currency: null,
    name: "",
    phoneNo: null,
    email: "",
    aboutCompany: "",
    onlineUrl: null,
    venue: null,
    country: null,
    state: null,
    city: null,
    selectedSkills: null,
    fromTiming: null,
    toTiming: null,
    fromWalkDate: null,
    toWalkDate: null,
    joiningDate: null,
    contractTimeperiod: "",
    billingRate: "PER_YEAR",
    mustHaveSkill: [],
    goodToHaveSkill: [],
    jd_skills_details: null,
  };

  const initialErrorJdState = {
    jobTitleField: {
      mandatory: false,
      patternFail: false,
    },
    companyName: {
      patternFail: false,
    },
    vacancy: {
      patternFail: false,
    },
    fromExpField: {
      mandatory: false,
      patternFail: false,
      lessThan: false,
    },
    toExpField: {
      mandatory: false,
      patternFail: false,
      greaterThan: false,
    },
    certifiedDegree: {
      mandatory: false,
    },
    specialization: {
      mandatory: false,
    },
    mustHaveSkill: {
      mandatory: false,
      patternFail: false,
    },
    minSalary: {
      patternFail: false,
      minSalary: false,
    },
    maxSalary: {
      patternFail: false,
      maxSalary: false,
    },
    joiningDate: {
      mandatory: false,
    },
    currency: {
      mandatory: false,
    },
    country: {
      mandatory: false,
    },
    name: {
      mandatory: false,
      patternFail: false,
    },
    phoneNo: {
      patternFail: false,
    },
    email: {
      mandatory: false,
      patternFail: false,
    },
    onlineUrl: {
      patternFail: false,
    },
    billingRate: {
      mandatory: false,
      patternFail: false,
    },
    contractTimeperiod: {
      mandatory: false,
      patternFail: false,
    },
    venue: {
      mandatory: false,
      patternFail: false,
    },
    fromWalkDate: {
      mandatory: false,
    },
    toWalkDate: {
      mandatory: false,
    },
    fromTiming: {
      mandatory: false,
    },
    toTiming: {
      mandatory: false,
      patternFail: false,
    },
    aboutCompany: {
      patternFail: false,
    },
    jobSummery: {
      patternFail: false,
    },
    jobDescription: {
      patternFail: false,
    },
  };

  const [createJdPage, setCreateJdPage] =
    useState<Ifields>(initialCreateJdState);

  const handleDropDownItem = (
    e: SelectChangeEvent<string>,
    id: string,
    isMandatoryDropDown: boolean
  ) => {
    const { value } = e.target;
    setCreateJdPage((formField) => {
      return {
        ...formField,
        [id]: value,
      };
    });
    setErrors((prev) => {
      return {
        ...prev,
        [id]: {
          mandatory: isMandatoryDropDown ? value === "" : false,
        },
      };
    });
  };

  const handleTextFieldChange = (
    id: string,
    e: ChangeEvent<HTMLTextAreaElement>,
    isMandatory: boolean,
    regex?: RegExp
  ) => {
    const newTextValue = e.target.value;
    setCreateJdPage((prevValue) => {
      return {
        ...prevValue,
        [id]: newTextValue,
      };
    });
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [id]: {
          mandatory: isMandatory ? newTextValue === "" : false,
          patternFail: !regex?.test(newTextValue),
        },
      };
    });
  };

  const [errors, setErrors] = useState(initialErrorJdState);

  const handleFormField = (
    event: React.ChangeEvent<HTMLInputElement>,
    isMandatoryField: boolean,
    regex?: RegExp
  ) => {
    const { value, id } = event.target;
    setCreateJdPage((formField) => {
      return {
        ...formField,
        [id]: value,
      };
    });

    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [id]: {
          mandatory: isMandatoryField ? value === "" : false,
          patternFail: value !== "" ? !regex?.test(value) : false,
          greaterThan: Number(value) < createJdPage?.fromExpField,
          maxSalary:
            createJdPage.minSalary !== null
              ? Number(value) < Number(createJdPage?.minSalary)
              : false,
        },
      };
    });
  };

  const handleCurrencyChange = (e: SelectChangeEvent<string>) => {
    const selectedCurrencyName = e.target.value;
    const tempCurrency =
      currencyItemData?.find(
        (currency) => currency.cc === selectedCurrencyName
      ) ?? null;
    setCreateJdPage((currency) => ({
      ...currency,
      currency: tempCurrency,
    }));
    setErrors((prev) => {
      return { ...prev, currency: { mandatory: tempCurrency === null } };
    });
  };

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    const selectedCountryName = e.target.value;
    const tempSelectedCountry =
      countryItems?.find((country) => country.name === selectedCountryName) ??
      null;
    if (tempSelectedCountry !== null) {
      dispatch(StateLocationToken(tempSelectedCountry.id));
    }
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      country: tempSelectedCountry,
    }));
  };

  const handleStateChange = (e: SelectChangeEvent<string>) => {
    const selectedStateName = e.target.value;
    const tempSelectedState =
      stateItems?.find((state) => state.name === selectedStateName) ?? null;
    if (tempSelectedState !== null && createJdPage.country !== null) {
      dispatch(
        CityLocationToken({
          countryId: createJdPage.country?.id,
          stateId: tempSelectedState.id,
        })
      );
    }
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      state: tempSelectedState,
    }));
  };

  const handleCityChange = (e: SelectChangeEvent<string>) => {
    const selectedCityName = e.target.value;
    const tempCityName =
      cityItems?.find((cities) => cities.name === selectedCityName) ?? null;

    setCreateJdPage((prevValue) => ({
      ...prevValue,
      city: tempCityName,
    }));
  };

  const industryItemsChange = (e: SelectChangeEvent<string>) => {
    const industryName = e.target.value;
    const tempIndustryName =
      industryItemsData?.find(
        (industry) => industry.industry_name === industryName
      ) ?? null;
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      industry: tempIndustryName,
    }));
  };

  const CategoryItemsChange = (e: SelectChangeEvent<string>) => {
    const categoryName = e.target.value;
    const tempCategoryName =
      categoryItemsData?.find(
        (category) => category.category_name === categoryName
      ) ?? null;
    if (tempCategoryName !== null) {
      dispatch(RolesThunkToken(tempCategoryName.id));
    }
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      category: tempCategoryName,
    }));
  };

  const RolesItemsChange = (e: SelectChangeEvent<string>) => {
    const rolesName = e.target.value;
    const tempRoleName =
      rolesItemsData?.find((roles) => roles.role_name === rolesName) ?? null;
    setCreateJdPage((prev) => ({
      ...prev,
      roles: tempRoleName,
    }));
  };

  const degreeItemsChange = (e: SelectChangeEvent<string>) => {
    const degreeName = e.target.value;
    const tempDegreeName =
      degreeItemsData.find(
        (degree) => degree.education_degree_name === degreeName
      ) ?? null;
    if (tempDegreeName !== null) {
      const degreeId = parseInt(tempDegreeName.id);
      dispatch(SpecializationThunk(degreeId));
    }
    setCreateJdPage((prev) => ({
      ...prev,
      certifiedDegree: tempDegreeName,
    }));

    setErrors((prev) => {
      return {
        ...prev,
        certifiedDegree: {
          mandatory: tempDegreeName === null ? true : false,
        },
      };
    });
  };

  const specilizationChange = (e: SelectChangeEvent<string>) => {
    const specialzationName = e.target.value;
    const tempspecialzationName =
      specializationItemsData?.find(
        (specialization) =>
          specialization.specialization_name === specialzationName
      ) ?? null;
    setCreateJdPage((prev) => ({
      ...prev,
      specialization: tempspecialzationName,
    }));

    setErrors((prev) => {
      return {
        ...prev,
        specialization: {
          mandatory: tempspecialzationName === null ? true : false,
        },
      };
    });
  };

  const formatDate = (date: any) => {
    if (!date) return "";
    const localDate = new Date(date);
    const offsetMilliseconds = localDate.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(localDate.getTime() - offsetMilliseconds);
    const formattedDate = adjustedDate.toISOString().split("T")[0];

    return formattedDate;
  };

  const handleDatePickChange = (newDate: Dayjs | null, id: string) => {
    setCreateJdPage((select) => ({
      ...select,
      [id]: newDate,
    }));
  };

  const formatTime = (time: any) => {
    if (!time) return "";
    return dayjs(time).format("HH:mm:ss A");
  };

  const onTimePickChange = (newTime: Dayjs | null, id: string) => {
    if (!newTime) return;
    setCreateJdPage((select) => ({
      ...select,
      [id]: newTime,
    }));
    setErrors((prev) => {
      return {
        ...prev,
        toTiming: {
          mandatory: false,
          patternFail:
            id === "fromTiming" && createJdPage.toTiming !== null
              ? !newTime.isBefore(createJdPage.toTiming)
              : id === "toTiming" && createJdPage.fromTiming !== null
              ? !createJdPage.fromTiming?.isBefore(newTime)
              : false,
        },
      };
    });
  };

  const BillingRateItems: IMenus[] = [
    {
      label: "Per Hour",
      value: "PER_HOUR",
    },
    {
      label: "Per Day",
      value: "PER_DAY",
    },
    {
      label: "Per Week",
      value: "PER_WEEK",
    },
    {
      label: "Per Year",
      value: "PER_YEAR",
    },
  ];

  const MenuItems: IMenus[] = [
    {
      label: "Full Time",
      value: FULL_TIME,
    },
    {
      label: "Contract",
      value: CONTRACT,
    },
    {
      label: "Walk In",
      value: WALK_IN_DRIVE,
    },
  ];

  const RegionItems: IMenus[] = [
    {
      label: "India",
      value: "india",
    },
  ];

  const StatusItems: IMenus[] = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Hold",
      value: "Hold",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
    {
      label: "Closed",
      value: "Closed",
    },
  ];

  const NoticePeriodItems: IMenus[] = [
    {
      label: "Immediate",
      value: "immediate",
    },
    {
      label: "Upto 15 days",
      value: "upto_15_days",
    },
    {
      label: "Upto 30 days",
      value: "upto_30_days",
    },
    {
      label: "Upto 45 days",
      value: "upto_45_days",
    },
    {
      label: "Upto 2 months",
      value: "upto_2_months",
    },
    {
      label: "Upto 3 months",
      value: "upto_3_months",
    },
    {
      label: "Upto 6 months",
      value: "upto_6_months",
    },
  ];

  const mandatoryType = (obj?: unknown): boolean => {
    return obj !== undefined && obj !== null && obj !== "";
  };

  const patternFailType = (
    value: string | undefined | null | any,
    regex: RegExp | any,
    isMandatory?: boolean
  ): boolean => {
    if (isMandatory) {
      return regex.test(value);
    } else {
      return true;
    }
  };

  const handleCreateJd = () => {
    if (
      mandatoryType(createJdPage.jobTitleField) &&
      patternFailType(
        createJdPage.jobTitleField,
        alphaNumericWithSpaceOnlySpecialCharPattern,
        true
      ) &&
      patternFailType(
        createJdPage.companyName,
        alphaNumericWithSpaceOnlySpecialCharPattern
      ) &&
      patternFailType(
        String(createJdPage.vacancy),
        onlyNumbersWithMaxFourDigits
      ) &&
      patternFailType(
        createJdPage.jobSummery,
        alphabetsWithTwoThousandChar,
        false
      ) &&
      patternFailType(
        createJdPage.jobDescription,
        alphabetsWithFiveThousandChar,
        false
      ) &&
      mandatoryType(createJdPage.fromExpField) &&
      (createJdPage.fromExpField !== null
        ? patternFailType(
            createJdPage.fromExpField,
            experienceFieldWithDecimalChar
          )
        : false) &&
      mandatoryType(createJdPage.toExpField) &&
      (createJdPage.toExpField !== null && createJdPage.fromExpField !== null
        ? patternFailType(
            createJdPage.toExpField,
            experienceFieldWithDecimalChar
          ) || createJdPage.toExpField < createJdPage.fromExpField
        : false) &&
      (createJdPage.jobDescription !== "" &&
      createJdPage.jobDescription !== null
        ? patternFailType(
            createJdPage.jobDescription,
            alphabetsWithFiveThousandChar,
            false
          )
        : true) &&
      createJdPage.mustHaveSkill.length > 0 &&
      (createJdPage.minSalary !== null && createJdPage.maxSalary !== null
        ? patternFailType(createJdPage.minSalary, digitsOnly) ||
          createJdPage.minSalary > createJdPage.maxSalary
        : false) &&
      mandatoryType(createJdPage.certifiedDegree) &&
      mandatoryType(createJdPage.specialization) &&
      patternFailType(String(createJdPage.minSalary), digitsOnly) &&
      patternFailType(String(createJdPage.maxSalary), digitsOnly) &&
      mandatoryType(createJdPage.country) &&
      mandatoryType(createJdPage.name) &&
      patternFailType(
        createJdPage.name,
        onlyAlphabetsWithMaxFifteenChar,
        true
      ) &&
      patternFailType(String(createJdPage.phoneNo), mobileNumberPattern) &&
      mandatoryType(createJdPage.email) &&
      patternFailType(createJdPage.email, validEmailPattern, true) &&
      patternFailType(
        createJdPage.aboutCompany,
        alphabetsWithTwoThousandChar,
        false
      ) &&
      patternFailType(createJdPage.onlineUrl, onlineUrl) &&
      (createJdPage.job_posting_type === CONTRACT
        ? mandatoryType(createJdPage.contractTimeperiod)
        : true) &&
      (createJdPage.job_posting_type === CONTRACT
        ? createJdPage.contractTimeperiod !== ""
          ? patternFailType(
              createJdPage.contractTimeperiod,
              alphanumericWithSpaceAndDotChar,
              true
            )
          : true
        : true) &&
      (createJdPage.job_posting_type === CONTRACT
        ? mandatoryType(createJdPage.joiningDate)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.venue)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? createJdPage.venue !== null
          ? patternFailType(
              createJdPage.venue,
              alphabetsWithTwoThousandChar,
              true
            )
          : true
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.fromWalkDate)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.toWalkDate)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.fromTiming)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.toTiming)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage.fromTiming !== null &&
      createJdPage.toTiming !== null
        ? createJdPage.fromTiming?.isBefore(createJdPage.toTiming)
        : true)
    ) {
      const skillsData = createJdPage.mustHaveSkill
        .map((skill) => {
          return {
            skill: skill,
            ismandatory: true,
          };
        })
        .concat(
          createJdPage.goodToHaveSkill.map((skill) => {
            return {
              skill: skill,
              ismandatory: false,
            };
          })
        );

      const FormData = {
        jd_details: {
          posting_type: createJdPage.job_posting_type,
          job_title: createJdPage.jobTitleField,
          job_end_date: createJdPage.job_end_date?.format("YYYY-MM-DD"),
          job_summary: createJdPage.jobSummery,
          job_description: createJdPage.jobDescription,
          industry: createJdPage.industry?.id,
          category: createJdPage.category?.id,
          roles: createJdPage.roles?.id,
          minimum_experience: Number(createJdPage.fromExpField),
          maximum_experience: Number(createJdPage.toExpField),
          certified_degree_name: createJdPage.certifiedDegree?.id,
          specialization: createJdPage.specialization?.id,
          currency:
            createJdPage.minSalary === "" && createJdPage.maxSalary === ""
              ? null
              : createJdPage.currency?.cc,
          minimum_salary:
            createJdPage.minSalary !== ""
              ? Number(createJdPage.minSalary)
              : null,
          maximum_salary:
            createJdPage.maxSalary !== ""
              ? Number(createJdPage.maxSalary)
              : null,
          contract_joining_date: createJdPage.joiningDate?.format("YYYY-MM-DD"),
          contract_duration: createJdPage.contractTimeperiod,
          contract_billing_rate: createJdPage.billingRate,
          walkin_city_name: createJdPage.venue,
          walkin_start_date: createJdPage.fromWalkDate?.format("YYYY-MM-DD"),
          walkin_end_date: createJdPage.toWalkDate?.format("YYYY-MM-DD"),
          walkin_start_time: createJdPage.fromTiming?.format(
            "HH:mm:ss YYYY-MM-DD"
          ),
          walkin_end_time: createJdPage.toTiming?.format("HH:mm:ss YYYY-MM-DD"),
          vacancy: createJdPage.vacancy,
          country: createJdPage.country?.name,
          state: createJdPage.state?.name,
          city: createJdPage.city?.name,
          company_name:
            createJdPage.companyName === "" ? null : createJdPage.companyName,
          about_company: createJdPage.aboutCompany,
          name: createJdPage.name,
          phone_number: createJdPage.phoneNo,
          email: createJdPage.email,
          apply_online_url: createJdPage.onlineUrl,
          is_short_jd: false,
          status: createJdPage.status,
        },
        jd_skills_details: skillsData,
      };
      dispatch(createJdThunk(FormData));
    } else {
      if (createJdPage?.jobTitleField === "") {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            jobTitleField: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      } else if (
        !patternFailType(
          createJdPage?.jobTitleField,
          alphaNumericWithSpaceOnlySpecialCharPattern
        )
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            jobTitleField: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      if (
        createJdPage?.companyName !== "" &&
        !patternFailType(
          createJdPage?.companyName,
          alphaNumericWithSpaceOnlySpecialCharPattern
        )
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            companyName: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      if (
        createJdPage.vacancy !== null &&
        Number(createJdPage.vacancy) > 0 &&
        !patternFailType(createJdPage?.vacancy, onlyNumbersWithMaxFourDigits)
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            vacancy: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      if (
        createJdPage.jobSummery !== null &&
        !patternFailType(createJdPage?.jobSummery, alphabetsWithTwoThousandChar)
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            jobSummery: {
              patternFail: true,
            },
          };
        });
      }

      if (
        createJdPage.jobDescription !== null &&
        !patternFailType(
          createJdPage?.jobDescription,
          alphabetsWithTwoThousandChar
        )
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            jobDescription: {
              patternFail: true,
            },
          };
        });
      }

      if (createJdPage.fromExpField > createJdPage.toExpField) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            toExpField: {
              mandatory: false,
              patternFail: false,
              greaterThan: true,
            },
          };
        });
      }

      if (createJdPage?.mustHaveSkill.length === 0) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            mustHaveSkill: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      } else {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            mustHaveSkill: {
              mandatory: false,
              patternFail: false,
            },
          };
        });
      }

      if (createJdPage?.certifiedDegree === null) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            certifiedDegree: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      }

      if (
        createJdPage.currency === null &&
        (createJdPage?.minSalary !== "" || createJdPage?.maxSalary !== "")
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            currency: {
              mandatory: true,
            },
          };
        });
      }

      if (createJdPage?.specialization === null) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            specialization: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      }

      if (
        createJdPage.job_posting_type === CONTRACT &&
        createJdPage?.joiningDate === null
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            joiningDate: {
              mandatory: true,
            },
          };
        });
      }

      if (
        createJdPage.job_posting_type === CONTRACT &&
        createJdPage?.contractTimeperiod === ""
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            contractTimeperiod: {
              mandatory: true,
              patternFail: true,
            },
          };
        });
      } else if (
        createJdPage.job_posting_type === CONTRACT &&
        createJdPage?.contractTimeperiod !== "" &&
        !patternFailType(
          createJdPage?.contractTimeperiod,
          alphanumericWithSpaceAndDotChar
        )
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            contractTimeperiod: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      if (
        (createJdPage.job_posting_type === WALK_IN_DRIVE &&
          createJdPage?.venue === "") ||
        createJdPage?.venue === null
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            venue: {
              mandatory: true,
              patternFail: true,
            },
          };
        });
      } else if (
        !patternFailType(createJdPage?.venue, alphabetsWithTwoThousandChar)
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            venue: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      if (
        createJdPage.job_posting_type === WALK_IN_DRIVE &&
        createJdPage.fromWalkDate === null
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            fromWalkDate: {
              mandatory: true,
            },
          };
        });
      }

      if (
        createJdPage.job_posting_type === WALK_IN_DRIVE &&
        createJdPage.toWalkDate === null
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            toWalkDate: {
              mandatory: true,
            },
          };
        });
      }

      if (
        createJdPage.job_posting_type === WALK_IN_DRIVE &&
        createJdPage?.fromTiming === null
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            fromTiming: {
              mandatory: true,
            },
          };
        });
      }

      if (
        createJdPage.job_posting_type === WALK_IN_DRIVE &&
        createJdPage?.toTiming === null
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            toTiming: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      }
      if (
        createJdPage.job_posting_type === WALK_IN_DRIVE &&
        createJdPage.fromTiming !== null &&
        createJdPage.toTiming !== null &&
        !createJdPage.fromTiming?.isBefore(createJdPage.toTiming)
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            toTiming: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }
      if (createJdPage?.country === null) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            country: {
              mandatory: true,
            },
          };
        });
      }

      if (createJdPage?.name === "") {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            name: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      } else if (
        !patternFailType(createJdPage?.name, onlyAlphabetsWithMaxFifteenChar)
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            name: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      if (createJdPage?.email === "") {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            email: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      } else if (!patternFailType(createJdPage?.email, validEmailPattern)) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            email: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      if (
        createJdPage.phoneNo !== null &&
        !patternFailType(createJdPage?.phoneNo, mobileNumberPattern)
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            phoneNo: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      if (
        createJdPage.aboutCompany !== null &&
        !patternFailType(
          createJdPage?.aboutCompany,
          alphabetsWithTwoThousandChar
        )
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            jobDescription: {
              patternFail: true,
            },
          };
        });
      }

      if (
        createJdPage.onlineUrl !== null &&
        !patternFailType(createJdPage?.onlineUrl, onlineUrl)
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            vacancy: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      setToaster((toast) => {
        return {
          ...toast,
          open: true,
          message: t("CREATE_JD.PLEASE_FILL_THE_FIELDS"),
          status: Status.Error,
        };
      });
    }
  };

  const handleCancelCreateJd = () => {
    dispatch(resetCreateJdState());
    setCreateJdPage(initialCreateJdState);
    setErrors(initialErrorJdState);
    navigate(jobsPage);
  };

  const countryItems: ILocationInfoResponse[] = useSelector(countryData);
  const stateItems: IStateInfoResponse[] = useSelector(stateData);
  const cityItems: ICityInfoResponse[] = useSelector(cityData);
  const industryItemsData: IIndustryData[] = useSelector(industryData);
  const categoryItemsData: ICategoryData[] = useSelector(categoryData);
  const rolesItemsData: IRolesData[] = useSelector(RolesData);
  const degreeItemsData: IEducationData[] = useSelector(certifiedDegreeData);
  const specializationItemsData: ISpecializationData[] =
    useSelector(SpecializationData);

  const skillsItemData: ISkillData[] = useSelector(skillData);
  const subSkillsData: ISubSkillData[] = useSelector(subskillData);
  const currencyItemData: ICurrencyResponse[] = useSelector(currencyData);
  const [selectedSkillData, setSelectedSkillData] = useState<ISkillData[]>();
  useEffect(() => {
    const goodToHaveSkills = skillsItemData.filter(
      (skill: any) => !createJdPage.mustHaveSkill.includes(skill.skill)
    );
    setSelectedSkillData(goodToHaveSkills);
  }, [createJdPage.mustHaveSkill]);

  const handleJobDescriptionFormSubmit = () => {
    const jobSkills = createJdPage.mustHaveSkill.map((skilldata: string) =>
      skilldata.toLowerCase()
    );
    const subSkills = subSkillsData.map((subSkill: ISubSkillData) =>
      subSkill.subskill.toLowerCase()
    );
    const nonMatching = jobSkills.filter(
      (jobSkill) => !subSkills.includes(jobSkill)
    );
    if (nonMatching.length > 0) {
      const payload = nonMatching.map((skill) => ({
        skill: skill,
        subskill: [skill],
      }));
      let addSkillData: IAddSkillRequest = {
        data: payload,
      };
      const response = dispatch(callAddSkill(addSkillData));
      handleCreateJd();
    } else {
      handleCreateJd();
    }
  };

  const getSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const allMatch = value.every((skill) =>
      alphabetsNumbersWithDotAndHash.test(skill)
    );

    if (allMatch) {
      setCreateJdPage((prev) => {
        return {
          ...prev,
          mustHaveSkill: value,
        };
      });
      setErrors((prev) => {
        return {
          ...prev,
          mustHaveSkill: {
            mandatory: false,
            patternFail: false,
          },
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          mustHaveSkill: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }
  };

  const getGoodToHaveSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    setCreateJdPage((prev) => {
      return {
        ...prev,
        goodToHaveSkill: value,
      };
    });
  };

  const handleToastClose = () => {
    setToaster((toast) => {
      return {
        ...toast,
        open: false,
        message: "",
      };
    });
  };

  useEffect(() => {
    if (createJdPage.minSalary === "" && createJdPage.maxSalary === "") {
      setCreateJdPage((prev) => {
        return { ...prev, currency: null };
      });
      setErrors((prev) => {
        return {
          ...prev,
          currency: {
            mandatory: false,
          },
        };
      });
    }
  }, [createJdPage.minSalary, createJdPage.maxSalary]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackBar {...toaster} handleClose={handleToastClose} />
        <Box>
          <Paper sx={{ py: 4 }}>
            <Grid container px={3.5} gap={3}>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <DropDownitems
                  placeholder={t("CREATE_JD.JOB_POSTING_TYPE")}
                  id="job_posting_type"
                  value={createJdPage.job_posting_type}
                  label={t("CREATE_JD.JOB_POSTING_TYPE")}
                  menuItems={MenuItems}
                  isMandatory={true}
                  handleChange={handleDropDownItem}
                />
                <Typography
                  fontSize={"var(--font-size-sm)"}
                  color={"var(--color-text-grey)"}
                  p={1.5}
                >
                  <span style={{ color: "var(--color-dark-red)" }}>
                    {t("CREATE_JD.NOTE")}:
                  </span>{" "}
                  {t("CREATE_JD.PLEASE_SELECT_A_JOB_TYPE")}
                </Typography>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <DropDownitems
                  placeholder={t("CREATE_JD.DOMAIN")}
                  id="domain"
                  value={createJdPage.domain}
                  label={t("CREATE_JD.DOMAIN")}
                  menuItems={RegionItems}
                  handleChange={handleDropDownItem}
                  isDisabled
                />
              </Grid>
            </Grid>
            <Grid container px={3.5} mt={2} gap={3}>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <AssistoTextFiled
                  label={t("CREATE_JD.JOB_TITLE")}
                  required
                  id="jobTitleField"
                  errorMessage={
                    errors?.jobTitleField?.mandatory ||
                    errors?.jobTitleField?.patternFail
                  }
                  helperText={
                    errors?.jobTitleField?.mandatory
                      ? t("CREATE_JD.PLEASE_ENTER_JOB_TITLE")
                      : errors?.jobTitleField?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : ""
                  }
                  value={createJdPage.jobTitleField ?? ""}
                  handleChange={(e) => {
                    handleFormField(
                      e,
                      true,
                      alphaNumericWithSpaceOnlySpecialCharPattern
                    );
                  }}
                />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <AssistoTextFiled
                  label={t("CREATE_JD.COMPANY_NAME")}
                  id="companyName"
                  errorMessage={errors?.companyName?.patternFail}
                  helperText={
                    errors?.companyName?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : ""
                  }
                  value={createJdPage.companyName ?? ""}
                  handleChange={(e) => {
                    handleFormField(
                      e,
                      false,
                      alphaNumericWithSpaceOnlySpecialCharPattern
                    );
                  }}
                />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <DropDownitems
                  placeholder={t("CREATE_JD.STATUS")}
                  id="status"
                  value={createJdPage.status}
                  label={t("CREATE_JD.STATUS")}
                  menuItems={StatusItems}
                  handleChange={handleDropDownItem}
                  isDisabled
                />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <FormControl fullWidth>
                  <InputLabel>{t("CREATE_JD.INDUSTRY")}</InputLabel>
                  <Select
                    id="industry"
                    value={createJdPage.industry?.industry_name ?? ""}
                    label={t("CREATE_JD.INDUSTRY")}
                    onChange={industryItemsChange}
                  >
                    {industryItemsData.map((menu: IIndustryData) => (
                      <MenuItem key={menu.id} value={menu.industry_name}>
                        {menu.industry_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <FormControl fullWidth>
                  <InputLabel>{t("CREATE_JD.CATEGORY")}</InputLabel>
                  <Select
                    id="category"
                    value={createJdPage.category?.category_name ?? ""}
                    label={t("CREATE_JD.CATEGORY")}
                    onChange={CategoryItemsChange}
                  >
                    {categoryItemsData.map((menu: ICategoryData) => (
                      <MenuItem key={menu.id} value={menu.category_name}>
                        {menu.category_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <FormControl fullWidth>
                  <InputLabel>{t("CREATE_JD.ROLES")}</InputLabel>
                  <Select
                    id="roles"
                    value={createJdPage.roles?.role_name ?? ""}
                    label={t("CREATE_JD.ROLES")}
                    onChange={RolesItemsChange}
                    disabled={!createJdPage.category}
                  >
                    {rolesItemsData.map((menu: IRolesData) => (
                      <MenuItem key={menu.id} value={menu.role_name}>
                        {menu.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!createJdPage.category && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_FIRST_CATEGORY")}*
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <AssistoDatePicker
                  value={createJdPage.job_end_date ?? null}
                  label={t("CREATE_JD.JOB_END_DATE")}
                  id="job_end_date"
                  handleDatePickChange={handleDatePickChange}
                  minDate={dayjs().add(1, "day")}
                />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
                <AssistoTextFiled
                  label={t("CREATE_JD.VACANCY")}
                  id="vacancy"
                  required={false}
                  value={String(createJdPage.vacancy ?? "")}
                  errorMessage={errors?.vacancy?.patternFail}
                  helperText={
                    errors?.vacancy?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : ""
                  }
                  handleChange={(e) => {
                    handleFormField(e, false, onlyNumbersWithMaxFourDigits);
                  }}
                />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
                <DropDownitems
                  placeholder={t("CREATE_JD.NOTICE_PERIOD")}
                  id="notice_period"
                  value={createJdPage.notice_period}
                  label={t("CREATE_JD.NOTICE_PERIOD")}
                  menuItems={NoticePeriodItems}
                  handleChange={handleDropDownItem}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                <Stack direction={"row"} gap={1}>
                  <BusinessCenterRoundedIcon />
                  <Typography
                    fontSize={"var(--font-size-18)"}
                    fontWeight={"var(--font-weight-500)"}
                  >
                    {t("CREATE_JD.WORK_EXPERIENCE")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xl={7.2} lg={7.2} md={12} sm={12}>
                <FormControl fullWidth error={errors?.jobSummery?.patternFail}>
                  <TextField
                    fullWidth
                    placeholder="Job Summary"
                    multiline
                    id="jobSummery"
                    value={createJdPage.jobSummery ?? ""}
                    rows={3}
                    inputProps={{
                      "data-testid": "jobSummery",
                    }}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      handleTextFieldChange(
                        "jobSummery",
                        e,
                        false,
                        alphabetsWithTwoThousandChar
                      )
                    }
                  />
                  {createJdPage.jobSummery !== "" &&
                    errors?.jobSummery?.patternFail && (
                      <FormHelperText>
                        {t(
                          "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                        )}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid
                item
                xl={3.5}
                lg={3.5}
                md={12}
                sm={12}
                display={"grid"}
                alignItems={"center"}
              >
                <Grid item xs={12} xl={12} sx={{ margin: "6px 0" }}>
                  <Grid container alignItems={"center"} gap={2}>
                    <Grid item xs={3} pl={1}>
                      <Typography>
                        {t("CREATE_JD.EXPERIENCE")}
                        <span style={{ marginLeft: "3px", color: "red" }}>
                          *
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={3.5}>
                      <AssistoTextFiled
                        required
                        errorMessage={
                          errors?.fromExpField?.mandatory ||
                          errors?.fromExpField?.patternFail ||
                          errors?.fromExpField?.lessThan
                        }
                        helperText={
                          errors?.fromExpField?.mandatory
                            ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                            : errors?.fromExpField?.patternFail
                            ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                            : ""
                        }
                        id="fromExpField"
                        label={t("CREATE_JD.FROM")}
                        value={String(createJdPage.fromExpField)}
                        handleChange={(e) => {
                          handleFormField(
                            e,
                            true,
                            experienceFieldWithDecimalChar
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={0.25}>
                      <hr />
                    </Grid>
                    <Grid item xs={3.5}>
                      <AssistoTextFiled
                        errorMessage={
                          errors?.toExpField?.mandatory ||
                          errors?.toExpField?.patternFail ||
                          errors?.toExpField?.greaterThan
                        }
                        required
                        helperText={
                          errors?.toExpField?.mandatory
                            ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                            : errors?.toExpField?.patternFail
                            ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                            : errors.toExpField.greaterThan
                            ? t("CREATE_JD.TO_VALUE_SHOULD_BE_GREATER")
                            : ""
                        }
                        id="toExpField"
                        label={t("CREATE_JD.TO")}
                        value={String(createJdPage.toExpField)}
                        handleChange={(e) => {
                          handleFormField(
                            e,
                            true,
                            experienceFieldWithDecimalChar
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={10.83} lg={10.83} md={12} sm={12}>
                <FormControl
                  fullWidth
                  error={errors?.jobDescription?.patternFail}
                >
                  <TextField
                    fullWidth
                    placeholder={t("CREATE_JD.JOB_DESCRIPTION")}
                    multiline
                    id="jobDescription"
                    value={createJdPage.jobDescription ?? ""}
                    rows={3}
                    inputProps={{
                      "data-testid": "jobDescription",
                    }}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      handleTextFieldChange(
                        "jobDescription",
                        e,
                        false,
                        alphabetsWithFiveThousandChar
                      )
                    }
                  />
                  {createJdPage.jobDescription !== "" &&
                    errors?.jobDescription?.patternFail && (
                      <FormHelperText>
                        {t(
                          "CREATE_JD.PLEASE_ENTER_MINIMUM_FIVE_THOUSAND_CHARACTER"
                        )}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                <Stack direction={"row"} gap={1}>
                  <AssistantIcon />
                  <Typography
                    fontSize={"var(--font-size-18)"}
                    fontWeight={"var(--font-weight-500)"}
                  >
                    {t("CREATE_JD.SKILLS_TITLE")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
                <AssistoCustomAddInputField
                  required
                  label={t("CREATE_JD.MUST_HAVE_SKILL")}
                  placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                  getValues={createJdPage.mustHaveSkill}
                  skillsList={skillsItemData}
                  onChange={getSkillsValue}
                  error={
                    errors?.mustHaveSkill?.mandatory ||
                    errors?.mustHaveSkill?.patternFail
                  }
                  helperText={
                    errors?.mustHaveSkill?.mandatory
                      ? t("CREATE_JD.PLEASE_ENTER_ATLEAST_ONE_MUST_HAVE_SKILL")
                      : errors.mustHaveSkill.patternFail
                      ? t("CREATE_JD.PLEASE_REMOVE_INVALID_DATA")
                      : ""
                  }
                />
              </Grid>
              <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
                <AssistoCustomAddInputField
                  required={false}
                  label={t("CREATE_JD.GOOD_TO_HAVE_SKILL")}
                  placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                  getValues={createJdPage.goodToHaveSkill}
                  skillsList={selectedSkillData}
                  onChange={getGoodToHaveSkillsValue}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                <Stack direction={"row"} gap={1}>
                  <SchoolIcon />
                  <Typography
                    fontSize={"var(--font-size-18)"}
                    fontWeight={"var(--font-weight-500)"}
                  >
                    {t("CREATE_JD.EDUCATION")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
                <FormControl
                  fullWidth
                  error={errors?.certifiedDegree?.mandatory}
                >
                  <InputLabel>
                    {t("CREATE_JD.CERTIFIED_DEGREE_NAME")}
                  </InputLabel>
                  <Select
                    id="certifiedDegree"
                    value={
                      createJdPage.certifiedDegree?.education_degree_name ?? ""
                    }
                    label={t("CREATE_JD.CERTIFIED_DEGREE_NAME")}
                    onChange={degreeItemsChange}
                    required
                  >
                    {degreeItemsData.map((menu: IEducationData) => (
                      <MenuItem
                        key={menu.id}
                        value={menu.education_degree_name}
                      >
                        {menu.education_degree_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.certifiedDegree?.mandatory && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_CERTIFIED_DEGREE_NAME")}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
                <FormControl
                  fullWidth
                  error={errors?.specialization?.mandatory}
                >
                  <InputLabel>{t("CREATE_JD.SPECIALIZATION")}</InputLabel>
                  <Select
                    id="specialization"
                    value={
                      createJdPage.specialization?.specialization_name ?? ""
                    }
                    label={t("CREATE_JD.SPECIALIZATION")}
                    onChange={specilizationChange}
                    required
                    disabled={!createJdPage.certifiedDegree}
                  >
                    {specializationItemsData.map(
                      (menu: ISpecializationData) => (
                        <MenuItem
                          key={menu.id}
                          value={menu.specialization_name}
                        >
                          {menu.specialization_name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {!createJdPage.certifiedDegree && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_FIRST_CERTIFIED_DEGREE")}*
                    </FormHelperText>
                  )}
                  {errors?.specialization.mandatory &&
                  createJdPage.certifiedDegree !== null &&
                  createJdPage.specialization === null ? (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_SPECIALIZATION")}*
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                <Stack direction={"row"} gap={1}>
                  <PaidIcon />
                  <Typography
                    fontSize={"var(--font-size-18)"}
                    fontWeight={"var(--font-weight-500)"}
                  >
                    {t("CREATE_JD.SALARY")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
                <AssistoTextFiled
                  errorMessage={errors?.minSalary?.patternFail}
                  required={false}
                  helperText={
                    errors?.minSalary?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : ""
                  }
                  id="minSalary"
                  label={t("CREATE_JD.MINIMUM_SALARY")}
                  value={String(
                    createJdPage.minSalary !== null
                      ? createJdPage.minSalary
                      : ""
                  )}
                  handleChange={(e) => {
                    handleFormField(e, false, digitsOnly);
                  }}
                />
              </Grid>
              <Grid item xs={3.5} lg={3.5} md={3.5} sm={12}>
                <AssistoTextFiled
                  errorMessage={
                    errors?.maxSalary?.patternFail ||
                    errors?.maxSalary?.maxSalary
                  }
                  required={false}
                  helperText={
                    errors?.maxSalary?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : errors?.maxSalary.maxSalary
                      ? t("CREATE_JD.MAX_SALARY_SHOULD_BE_GREATER")
                      : ""
                  }
                  id="maxSalary"
                  label={t("CREATE_JD.MAXIMUM_SALARY")}
                  value={String(
                    createJdPage.maxSalary !== null
                      ? createJdPage.maxSalary
                      : ""
                  )}
                  handleChange={(e) => {
                    handleFormField(e, false, digitsOnly);
                  }}
                />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <FormControl fullWidth error={errors?.currency?.mandatory}>
                  <InputLabel>{t("CREATE_JD.CURRENCY")}</InputLabel>
                  <Select
                    id="currency"
                    value={createJdPage.currency?.cc ?? ""}
                    label={t("CREATE_JD.CURRENCY")}
                    onChange={handleCurrencyChange}
                    inputProps={{
                      "data-testid": "currency",
                    }}
                    disabled={
                      createJdPage.minSalary === "" &&
                      createJdPage.maxSalary === ""
                    }
                  >
                    {currencyItemData.map((menu: ICurrencyResponse) => (
                      <MenuItem key={menu.cc} value={menu.cc}>
                        {menu.cc}
                      </MenuItem>
                    ))}
                  </Select>
                  {(!createJdPage?.minSalary ||
                    createJdPage?.minSalary === "") && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_MINIMUM_SALARY")}*
                    </FormHelperText>
                  )}
                  {errors?.currency?.mandatory ? (
                    <FormHelperText>
                      {t("CREATE_JD.CURRENCY_IS_MANDATORY")}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                <Stack direction={"row"} gap={1}>
                  <FmdGoodIcon />
                  <Typography
                    fontSize={"var(--font-size-18)"}
                    fontWeight={"var(--font-weight-500)"}
                  >
                    {t("CREATE_JD.JOB_LOCATION")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <FormControl
                  fullWidth
                  error={
                    (createJdPage.country === null ||
                      createJdPage.country?.name === "") &&
                    errors?.country?.mandatory
                  }
                >
                  <InputLabel>
                    {t("CREATE_JD.COUNTRY")}
                    {errors?.country?.mandatory && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </InputLabel>
                  <Select
                    id="country"
                    value={createJdPage.country?.name ?? ""}
                    label={t("CREATE_JD.COUNTRY")}
                    onChange={handleCountryChange}
                    inputProps={{
                      "data-testid": "country",
                    }}
                  >
                    {countryItems.map((menu: ILocationInfoResponse) => (
                      <MenuItem key={menu.id} value={menu.name}>
                        {menu.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {(createJdPage.country === null ||
                    createJdPage.country?.name === "") &&
                    errors?.country?.mandatory && (
                      <FormHelperText>
                        {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <FormControl fullWidth>
                  <InputLabel>{t("CREATE_JD.STATE")}</InputLabel>
                  <Select
                    id="state"
                    value={createJdPage.state?.name ?? ""}
                    label={t("CREATE_JD.STATE")}
                    onChange={handleStateChange}
                    disabled={!createJdPage.country}
                  >
                    {stateItems.map((menu: ILocationInfoResponse) => (
                      <MenuItem key={menu.id} value={menu.name}>
                        {menu.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!createJdPage.country && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <FormControl fullWidth>
                  <InputLabel>{t("CREATE_JD.CITY")}</InputLabel>
                  <Select
                    id="city"
                    value={createJdPage.city?.name ?? ""}
                    label={t("CREATE_JD.CITY")}
                    onChange={handleCityChange}
                    disabled={!createJdPage.country || !createJdPage.state}
                  >
                    {cityItems.map((menu: ILocationInfoResponse) => (
                      <MenuItem key={menu.id} value={menu.name}>
                        {menu.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!createJdPage.country && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_COUNTRY_&_STATE")}*
                    </FormHelperText>
                  )}
                  {createJdPage.country && !createJdPage.state && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_STATE")}*
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {createJdPage.job_posting_type === CONTRACT && (
                <>
                  <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                    <Stack direction={"row"} gap={1}>
                      <ContactsIcon />
                      <Typography
                        fontSize={"var(--font-size-18)"}
                        fontWeight={"var(--font-weight-500)"}
                      >
                        {t("CREATE_JD.CONTRACT")}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                    <AssistoDatePicker
                      id="joiningDate"
                      value={createJdPage.joiningDate ?? null}
                      label={t("CREATE_JD.EARLY_JOINING_DATE")}
                      handleDatePickChange={handleDatePickChange}
                      minDate={dayjs().add(1, "day")}
                      error={
                        createJdPage?.joiningDate === null &&
                        errors?.joiningDate?.mandatory
                      }
                      helperText={
                        createJdPage?.joiningDate === null &&
                        errors?.joiningDate?.mandatory
                          ? t("CREATE_JD.EARLY_JOINING_DATE_MANDATORY")
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                    <AssistoTextFiled
                      errorMessage={
                        errors?.contractTimeperiod?.mandatory ||
                        errors?.contractTimeperiod?.patternFail
                      }
                      required
                      helperText={
                        errors?.contractTimeperiod?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : errors?.contractTimeperiod?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : ""
                      }
                      id="contractTimeperiod"
                      label={t("CREATE_JD.CONTRACT_TIME_PERIOD")}
                      value={String(
                        createJdPage.contractTimeperiod !== null
                          ? createJdPage.contractTimeperiod
                          : ""
                      )}
                      handleChange={(e) => {
                        handleFormField(
                          e,
                          true,
                          alphanumericWithSpaceAndDotChar
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                    <DropDownitems
                      placeholder={t("CREATE_JD.BILLING_RATE")}
                      id="billingRate"
                      value={createJdPage.billingRate ?? ""}
                      label={t("CREATE_JD.BILLING_RATE")}
                      menuItems={BillingRateItems}
                      isMandatory={true}
                      handleChange={handleDropDownItem}
                    />
                  </Grid>
                </>
              )}
              {createJdPage.job_posting_type === WALK_IN_DRIVE && (
                <>
                  <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                    <Stack direction={"row"} gap={1}>
                      <SaveAsIcon />
                      <Typography
                        fontSize={"var(--font-size-18)"}
                        fontWeight={"var(--font-weight-500)"}
                      >
                        {t("CREATE_JD.WALK_IN_DETAILS")}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={7.2} lg={7.2} md={7.2} sm={12}>
                    <FormControl
                      fullWidth
                      error={
                        (createJdPage?.venue === "" ||
                          createJdPage.venue === null) &&
                        errors?.venue?.mandatory &&
                        errors?.venue?.patternFail
                      }
                    >
                      <TextField
                        fullWidth
                        placeholder={t("CREATE_JD.VENUE")}
                        multiline
                        id="venue"
                        value={createJdPage.venue ?? ""}
                        rows={3}
                        inputProps={{
                          "data-testid": "venue",
                        }}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                          handleTextFieldChange(
                            "venue",
                            e,
                            true,
                            alphabetsWithTwoThousandChar
                          )
                        }
                      />
                      {errors?.venue?.mandatory === null && (
                        <FormHelperText>
                          {t(
                            "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                          )}
                        </FormHelperText>
                      )}
                      {createJdPage.venue !== "" &&
                        errors?.venue?.patternFail && (
                          <FormHelperText>
                            {t(
                              "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                            )}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                    <AssistoDatePicker
                      value={createJdPage.fromWalkDate ?? null}
                      label={t("CREATE_JD.WALK_IN_DATE_FROM")}
                      id="fromWalkDate"
                      handleDatePickChange={handleDatePickChange}
                      minDate={dayjs().add(1, "day")}
                      error={
                        createJdPage?.fromWalkDate === null &&
                        errors?.fromWalkDate?.mandatory
                      }
                      helperText={
                        createJdPage?.fromWalkDate === null &&
                        errors?.fromWalkDate?.mandatory
                          ? t("CREATE_JD.WALK_IN_DATE_IS_MANDATORY")
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                    <AssistoDatePicker
                      value={createJdPage.toWalkDate ?? null}
                      id="toWalkDate"
                      label={t("CREATE_JD.WALK_IN_DATE_TO")}
                      handleDatePickChange={handleDatePickChange}
                      minDate={dayjs().add(2, "day")}
                      error={
                        createJdPage?.toWalkDate === null &&
                        errors?.toWalkDate?.mandatory
                      }
                      helperText={
                        createJdPage?.toWalkDate === null &&
                        errors?.fromWalkDate?.mandatory
                          ? t("CREATE_JD.WALK_IN_DATE_IS_MANDATORY")
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                    <AssistoTimePicker
                      label={t("CREATE_JD.FROM_TIMING")}
                      onTimePickChange={onTimePickChange}
                      id="fromTiming"
                      value={createJdPage.fromTiming ?? null}
                      error={
                        createJdPage.fromTiming === null &&
                        errors?.fromTiming?.mandatory
                      }
                      helperText={
                        createJdPage?.fromTiming === null &&
                        errors?.fromTiming?.mandatory
                          ? t("CREATE_JD.FROM_TIMING_IS_MANDATORY")
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                    <AssistoTimePicker
                      label={t("CREATE_JD.TO_TIMING")}
                      id="toTiming"
                      onTimePickChange={onTimePickChange}
                      value={createJdPage.toTiming ?? null}
                      error={
                        errors?.toTiming?.mandatory ||
                        errors?.toTiming?.patternFail
                      }
                      helperText={
                        errors?.toTiming?.mandatory
                          ? t("CREATE_JD.TO_TIMING_IS_MANDATORY")
                          : errors?.toTiming?.patternFail
                          ? t("CREATE_JD.TO_TIMING_SHOULD_BE_GREATER")
                          : ""
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                <Stack direction={"row"} gap={1}>
                  <ContactsIcon />
                  <Typography
                    fontSize={"var(--font-size-18)"}
                    fontWeight={"var(--font-weight-500)"}
                  >
                    {t("CREATE_JD.CONTACT")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <AssistoTextFiled
                  label={t("CREATE_JD.NAME")}
                  required
                  id="name"
                  value={createJdPage.name ?? ""}
                  errorMessage={
                    errors?.name?.mandatory || errors?.name?.patternFail
                  }
                  helperText={
                    errors?.name?.mandatory
                      ? t("CREATE_JD.PLEASE_ENTER_NAME")
                      : errors?.name?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : ""
                  }
                  handleChange={(e) => {
                    handleFormField(e, true, onlyAlphabetsWithMaxFifteenChar);
                  }}
                />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <AssistoTextFiled
                  errorMessage={errors?.phoneNo?.patternFail}
                  required={false}
                  helperText={
                    errors?.phoneNo?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : ""
                  }
                  id="phoneNo"
                  label={t("CREATE_JD.PHONE_NUMBER")}
                  value={String(
                    createJdPage.phoneNo !== null ? createJdPage.phoneNo : ""
                  )}
                  handleChange={(e) => {
                    handleFormField(e, false, mobileNumberPattern);
                  }}
                />
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                <AssistoTextFiled
                  label={t("CREATE_JD.EMAIL")}
                  required
                  id="email"
                  value={createJdPage.email ?? ""}
                  errorMessage={
                    errors?.email?.mandatory || errors?.email?.patternFail
                  }
                  helperText={
                    errors?.email?.mandatory
                      ? t("CREATE_JD.PLEASE_ENTER_EMAIL")
                      : errors?.email?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : ""
                  }
                  handleChange={(e) => {
                    handleFormField(e, true, validEmailPattern);
                  }}
                />
              </Grid>
              <Grid item xl={7.2} lg={7.2} md={7.2} sm={12}>
                <FormControl
                  fullWidth
                  error={errors?.aboutCompany?.patternFail}
                >
                  <TextField
                    fullWidth
                    placeholder={t("CREATE_JD.ABOUT_COMPANY")}
                    multiline
                    id="aboutCompany"
                    value={createJdPage.aboutCompany ?? ""}
                    rows={3}
                    inputProps={{
                      "data-testid": "aboutCompany",
                    }}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      handleTextFieldChange(
                        "aboutCompany",
                        e,
                        false,
                        alphabetsWithTwoThousandChar
                      )
                    }
                  />
                  {createJdPage.aboutCompany !== "" &&
                    errors?.aboutCompany?.patternFail && (
                      <FormHelperText>
                        {t(
                          "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                        )}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid
                item
                xl={3.5}
                lg={3.5}
                md={6}
                sm={12}
                alignItems={"center"}
                display={"grid"}
              >
                <AssistoTextFiled
                  errorMessage={errors?.onlineUrl?.patternFail}
                  required={false}
                  helperText={
                    errors?.onlineUrl?.patternFail
                      ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                      : ""
                  }
                  id="onlineUrl"
                  label={t("CREATE_JD.APPLY_ONLINE_URL")}
                  value={String(
                    createJdPage.onlineUrl !== null
                      ? createJdPage.onlineUrl
                      : ""
                  )}
                  handleChange={(e) => {
                    handleFormField(e, true, onlineUrl);
                  }}
                />
              </Grid>
              <Stack direction={"row"} gap={2} mt={2}>
                <AssistoButton
                  buttonVarient="contained"
                  name="Save"
                  buttonClick={handleJobDescriptionFormSubmit}
                />
                <AssistoButton
                  buttonVarient="outlined"
                  name="Cancel"
                  buttonClick={handleCancelCreateJd}
                />
              </Stack>
            </Grid>
          </Paper>
        </Box>
      </ThemeProvider>
    </>
  );
}
export default JobDescription;
