import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import {
  callUploadResumes,
  IUploadFileResponse,
} from "../thunks/uploadResumesThunk";

export interface IinitialFileData {
  files: IUploadFileResponse;
  loading: string;
}

const initialState: IinitialFileData = {
  files: {
    status_message: "",
    data: {
      job_status_id: 0,
      total_files: 0,
      background_status: "",
    },
  },
  loading: "",
};

export const uploadResumesSlice = createSlice({
  name: "uploadResumes",
  initialState,
  reducers: {
    setResumeInfo: (state, action: PayloadAction<IUploadFileResponse>) => {
      state.files = action.payload;
    },
    resetUploadFilesState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(callUploadResumes.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(callUploadResumes.fulfilled, (state, action: any) => {
        state.loading = "succeeded";
        state.files = action.payload.data;
        localStorage.setItem(
          "job_status_id",
          action.payload.data.data.job_status_id
        );
      })
      .addCase(callUploadResumes.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default uploadResumesSlice.reducer;
export const { setResumeInfo, resetUploadFilesState } =
  uploadResumesSlice.actions;
export const TotalNumberOfFilesUploaded = (state: RootState) =>
  state.resumesInfo.files.data.total_files;
export const getUploadApiStatus = (state: RootState) =>
  state.resumesInfo.loading;
export const getUploadStatusJobId = (state: RootState) =>
  state.resumesInfo.files.data.job_status_id;
export const resumeInfoData = (state: RootState) => state.resumesInfo;
