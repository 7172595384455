export const alphaNumericWithSpaceOnlySpecialCharPattern =
  /^[a-zA-Z0-9 .#]{3,50}$/;
export const alphaNumericWithAllSpecialCharsPattern = /^[A-Za-z0-9\W_]*$/;
export const alphaNumericOnlyPattern = /^[a-zA-Z0-9\s]*$/;
export const validEmailPattern =
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const alphaNumericWithCapitalizedCharPattern = /^[A-Z0-9]+$/;
export const tenDigitMobileNumberPattern = /^\d{10}$/;
export const digitsOnly = /^\d{5,9}$/;
export const piCodePattern = /^(?!0)\d{4}(?:\d{2})?$/;
export const eightDigitStrongPasswordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8}$/;
export const domainName = /^(?!-+$)[a-z0-9-]+$/;
export const nameWithUprLowrSpaceBetPattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
export const nameWithUprLowrSpaceBetNumbersPattern =
  /^(?!^[0-9]+$)[0-9A-Za-z]+(?: [0-9A-Za-z]+)*$/;
export const employeeIdAlphabetFormatPattern = /^([A-Z]{3,4})$/;
export const mobileNumberPattern = /^[\+\d][\d\s\-]{13,22}$/;
export const pfNumberValidation =
  /^[A-Z]{2}[\s\/]?[A-Z]{3}[\s\/]?[0-9]{7}[\s\/]?[0-9]{3}[\s\/]?[0-9]{7}$/;
export const panRegex = /^([A-Z]{5})(\d{4})([A-Z])$/;
export const contactNumberTenDigitWithoutCode = /^\d{10}$/;
export const onlyNumbersWithMaxFourDigits = /^\d{1,4}$/;
export const onlyNumbersWithTwoDigits = /^\d{1,2}$/;
export const onlineUrl =
  /^\b(?:https?|ftp):\/\/[A-Za-z0-9]+\.[A-Za-z]{2,}(?:\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*)?/;
export const onlyAlphabets = /^[A-Za-z]+$/;
export const alphaNumericWithDotAndSpaceWithFiftyMaxChar = /^[a-zA-Z. ]{3,50}$/;
export const alphabetsWithOnlySpaceInclude =
  /^[a-zA-Z]{3,10}(?:\s[a-zA-Z]{3,10})*$/;
export const alphabetsWithTwoThousandChar = /^.{50,2000}$/;
export const alphabetsWithFiveThousandChar = /^.{50,5000}$/;
export const onlyAlphabetsWithMaxFifteenChar = /^[a-zA-Z. ]{3,16}$/;
export const experienceFieldWithDecimalChar = /^\d{0,2}(\.\d{1})?$/;
export const alphabetsNumbersWithDotAndHash = /^[A-Za-z0-9.#\/+\-]*$/;
export const alphanumericWithSpaceAndDotChar = /^[a-zA-Z0-9 .-]*$/;
