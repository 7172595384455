import { ThemeProvider } from "styled-components";
import ForbiddenImg from "../../assets/images/forbidden-img.svg";
import theme from "../Theme/theme";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReplyIcon from "@mui/icons-material/Reply";
import { AssistoButton } from "../Button";
import { NAMESPACE } from "../../utils/i18nUtils";
import { dashboardPath } from "../../pages/Routes/routes";

function ForbiddenScreen() {
  const { t } = useTranslation(NAMESPACE.COMMON);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Stack className="forbidden-screen">
          <img
            className="forbidden-screen__forbidden-img"
            src={ForbiddenImg}
            alt="forbidden-img"
            style={{ margin: "0 auto" }}
          />
          <Typography
            variant="h4"
            fontWeight={"400"}
            textAlign={"center"}
            mb={1}
            mt={2}
          >
            {t("COMMON.FORBIDDEN_MESSAGE")}
          </Typography>
          <Typography textAlign={"center"}>
            {t("COMMON.RESTRICTED_ACCESS")}
          </Typography>
          <Typography textAlign={"center"} mb={2}>
            {t("COMMON.PLEASE_VISIT_ADMINISTRATOR")}
          </Typography>
          <Stack display={"inline-block"} m={"0 auto"}>
            <AssistoButton
              name={t("COMMON.GO_BACK_TO_DASHBOARD")}
              buttonVarient="contained"
              startIcon={<ReplyIcon />}
              buttonClick={() => (window.location.href = dashboardPath)}
            />
          </Stack>
        </Stack>
      </ThemeProvider>
    </>
  );
}

export default ForbiddenScreen;
