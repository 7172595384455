import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface ICategoryResponse {
  status_message: string;
  data: ICategoryData[];
}

export interface ICategoryData {
  id: string;
  category_name: string;
}

export const CategoryThunkToken = createAsyncThunk(
  "category/categoryInfo",
  async () => {
    const domain_name = "india";
    const CategoryEndPoint = `job_portal/categories?domain_name=${domain_name}`;
    try {
      const response = await Get<ICategoryResponse, undefined>(
        CategoryEndPoint,
        undefined,
        {},
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
