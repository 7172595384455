import { Fade, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CSSProperties, ReactNode } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
  title?: string;
  style?: CSSProperties;
  isCloseButtonNeeded?: boolean;
}

export default function AssistoModal(props: IModalProps) {
  const { open, handleClose, children, title, style, isCloseButtonNeeded } =
    props;

  return (
    <>
      <Modal sx={{ p: 4 }} open={open} onClose={handleClose}>
        <Fade in={open} style={{ backgroundColor: "#F4F4F4" }}>
          <Box sx={style}>
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
              <Typography id="spring-modal-title" variant="body2">
                {title}
              </Typography>
              {isCloseButtonNeeded && (
                <CloseOutlinedIcon
                  className="inactive-breadcrumb"
                  onClick={handleClose}
                />
              )}
            </Stack>
            {children}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
