import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import JobsIcon from "../../assets/images/job-details.svg";
import { ThemeProvider } from "styled-components";
import theme from "../../widgets/Theme/theme";
import { AssistoTable, Column } from "../../widgets/Table";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { ITableData } from "../Dashboard/Components/Table";
import { useEffect, useState } from "react";
import AssistoSearch from "../../widgets/AssistoSearchField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AssistoButton } from "../../widgets/Button";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import AssistoToolTip, { Position } from "../../widgets/AssistoToolTip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { createJdPage, forbiddenPage } from "../Routes/routes";
import { getJobsListPage } from "../../thunks/jobsPageListthunk";
import {
  GetCompleteJobsListData,
  GetTotalJobsData,
} from "../../reducers/jobsPageListreducers";
import { loggedInUserPermissions } from "../../utils/permissionManagement";
import { isDefined } from "../../utils/helper";
import Pagination from "../../widgets/Pagination";
import AvatarChips, { StatusColor } from "../../widgets/AssistoChip";

const JdColumns: Column[] = [
  { id: "id", label: "Job Id", minWidth: 50 },
  { id: "job_title", label: "Job Title", minWidth: 150 },
  {
    id: "matched_profiles",
    label: "Matched Profile",
    minWidth: 80,
  },
  {
    id: "experience",
    label: "Experience",
    minWidth: 100,
  },
  {
    id: "must_have_skills",
    label: "Must have skill",
    minWidth: 150,
  },
  {
    id: "nice_to_have_skills",
    label: "Nice to have skill",
    minWidth: 150,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
  },
  {
    id: "posting_type",
    label: "Posting Type",
    minWidth: 150,
  },
];

function JobsPage() {
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [rowsPerPageNum, setRowsPerPageNum] = useState<number>(10);

  const { t } = useTranslation(NAMESPACE.RESUMESLIST);
  const dispatch: AppDispatch = useDispatch();
  const CompleteJobsAllData = useSelector(GetCompleteJobsListData);
  const totalJobsCount = useSelector(GetTotalJobsData);
  const navigate = useNavigate();
  const tableData: ITableData[] = [
    {
      title: "HOME_PAGE.JD_TABLE_TITLE",
      tableColumn: JdColumns,
      tableRows: CompleteJobsAllData,
      headerFontSize: "14px",
      defaultMessage: "HOME_PAGE.PLEASE_CREATE_JD",
    },
  ];

  useEffect(() => {
    if (
      isDefined(loggedInUserPermissions) &&
      loggedInUserPermissions.includes("VIEW_JOBS")
    ) {
      dispatch(
        getJobsListPage({
          page: currentPageNum,
          pageSize: Number(rowsPerPageNum),
        })
      );
    } else {
      navigate(forbiddenPage);
    }
  }, [currentPageNum, rowsPerPageNum]);

  const onPageNumberClick = (clickedPageNumber: number) => {
    setCurrentPageNum(clickedPageNumber);
  };

  const onRowsPerPageChange = (value: number) => {
    setRowsPerPageNum(value);
    setCurrentPageNum(1);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box width={"98%"} my={4}>
          <Paper>
            <Stack p={4} direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                <img width={"22%"} src={JobsIcon} alt="resumesIcon" />
                <Stack direction={"column"} width={"100%"}>
                  <Typography
                    variant="h5"
                    fontWeight={"var(--font-weight-500)"}
                  >
                    {t("RESUMES_PAGE.JOB_DETAILS")}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={3}>
                <AssistoSearch />
                <AssistoButton
                  buttonVarient="contained"
                  name={t("RESUMES_PAGE.CREATE_NEW_JD")}
                  endIcon={<AddCircleOutlineIcon />}
                  buttonClick={() => navigate(createJdPage)}
                />
                <FilterAltIcon fontSize="medium" />
              </Stack>
            </Stack>
          </Paper>

          <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Stack className="hire-assisto__white-bg">
                  <AssistoTable
                    rows={CompleteJobsAllData?.map((row, index: number) => {
                      return {
                        id: rowsPerPageNum * (currentPageNum - 1) + index + 1,
                        job_title:
                          row.job_title.length > 15 ? (
                            <AssistoToolTip
                              position={Position.Top}
                              title={row.job_title}
                              content={row.job_title
                                .substring(0, 25)
                                .concat("...")}
                            />
                          ) : (
                            row.job_title
                          ),
                        matched_profiles: row.matched_profiles,
                        experience: `${row.minimum_experience}-${row.maximum_experience} years`,
                        must_have_skills:
                          row.must_have_skills.length > 2 ? (
                            <AssistoToolTip
                              position={Position.Top}
                              title={row.must_have_skills.join(" , ")}
                              content={row.must_have_skills
                                .slice(0, 2)
                                .join(" , ")
                                .concat("...")}
                            />
                          ) : (
                            row.must_have_skills
                          ),
                        nice_to_have_skills:
                          row.nice_to_have_skills.length > 2 ? (
                            <AssistoToolTip
                              position={Position.Top}
                              title={row.nice_to_have_skills.join(" , ")}
                              content={row.nice_to_have_skills
                                .slice(0, 2)
                                .join(" , ")
                                .concat("...")}
                            />
                          ) : (
                            row.nice_to_have_skills
                          ),
                        statusName: row.status,
                        status: (
                          <AvatarChips
                            color={StatusColor.ACTIVE}
                            label={row.status}
                          />
                        ),
                        posting_type: row.posting_type.replace("_", " "),
                      };
                    })}
                    columns={JdColumns}
                    defaultMessage={t("RESUMES_PAGE.NO_DATA_AVAILABLE")}
                    headerCheckBoxNeeded={true}
                    headerFontSize={"14px"}
                    dataCheckBoxNeeded={true}
                    isPaginationRequired={false}
                  />
                  <Stack my={2}>
                    <Pagination
                      totalItems={totalJobsCount}
                      currentPageNumber={currentPageNum}
                      rowsPerPage={rowsPerPageNum}
                      onPageNumberClick={onPageNumberClick}
                      onRowsPerPageChange={onRowsPerPageChange}
                      rowsPerPageList={[10, 25, 50, "All"]}
                    />
                  </Stack>
                </Stack>
              </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default JobsPage;
