export const assisto_page = "http://devassisto.com";
export const assisto_qa_page = "http://test.devassisto.com";
export const host_url = "http://hireassisto.devassisto.com/";
export const qa_host_url = "https://hireapi.devassisto.com/";
export const localhost_url = "http://localhost:3000/";
export const MAX_LIMIT_FOR_UPLOAD_RESUME = 1000;
export const FULL_TIME = "Full Time";
export const CONTRACT = "Contract";
export const WALK_IN_DRIVE = "Walkin Drive";
export const SHORT_JD = "Short Jd";
