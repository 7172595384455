import { ThemeProvider } from "styled-components";
import theme from "../../widgets/Theme/theme";
import AssistoBreadcrumbs, {
  IBreadCrumbItem,
} from "../../widgets/AssistoBreadcrumb";
import { dashboardPath, forbiddenPage, jobsPage } from "../Routes/routes";
import AssistoTabs, { TabInfo } from "../../widgets/Tabs";
import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import CreateManualCreation from "./jobDescription";
import ShortJobDescription from "./shortJobDescription";
import ActiveJdIcon from "../../assets/images/active-job-description.svg";
import InactiveJdIcon from "../../assets/images/inactive-job-description.svg";
import ActiveShortJdIcon from "../../assets/images/active-short-jd.svg";
import InactiveShortJdIcon from "../../assets/images/inactive-short-jd.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { CountryLocationToken } from "../../thunks/countryLocationThunk";
import { useEffect } from "react";
import { IndustryThunkToken } from "../../thunks/industryThunk";
import { CategoryThunkToken } from "../../thunks/categoryThunk";
import { EducationDegreeToken } from "../../thunks/educationDegreeThunk";
import { GetSkillThunk } from "../../thunks/getSkillthunk";
import { CurrencyTokenApi } from "../../thunks/currencyThunk";
import { loggedInUserPermissions } from "../../utils/permissionManagement";
import { isDefined } from "../../utils/helper";
import { GetSubSkillThunk } from "../../thunks/getSubSkillThunk";

function CreateJdPage() {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const tabsComponent: TabInfo[] = [
    {
      label: "Job Description",
      activeImage: ActiveJdIcon,
      inactiveImage: InactiveJdIcon,
      content: <CreateManualCreation />,
    },
    {
      label: "Short Description",
      activeImage: ActiveShortJdIcon,
      inactiveImage: InactiveShortJdIcon,
      content: <ShortJobDescription />,
    },
  ];

  const breadcrumbItems: IBreadCrumbItem[] = [
    {
      label: "Jobs",
      onClick: () => navigate(jobsPage),
    },
    {
      label: "Create New Jd",
    },
  ];

  useEffect(() => {
    if (
      isDefined(loggedInUserPermissions) &&
      loggedInUserPermissions.includes("CREATE_JOBS")
    ) {
      dispatch(CountryLocationToken());
      dispatch(IndustryThunkToken());
      dispatch(CategoryThunkToken());
      dispatch(EducationDegreeToken());
      dispatch(GetSkillThunk());
      dispatch(CurrencyTokenApi());
      dispatch(GetSubSkillThunk());
    } else {
      navigate(forbiddenPage);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AssistoBreadcrumbs items={breadcrumbItems} />
        <Paper sx={{ marginBottom: 4, width: "98%" }}>
          <AssistoTabs tabs={tabsComponent} />
        </Paper>
      </ThemeProvider>
    </>
  );
}

export default CreateJdPage;
