import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callUploadResumeCount } from "../thunks/totalResumesCountThunk";
import activeJobIcon from "../assets/images/active.svg";
import jobPostingIcon from "../assets/images/job-posting.svg";
import jobClosedIcon from "../assets/images/job-closed.svg";
import profilesIcon from "../assets/images/total-profile.svg";
import { RootState } from "../store/store";

interface ItotalFilesData {
  status_message: string;
  data: {
    total_jobs_count: number;
    active_jobs_count: number;
    inactive_jobs_count: number;
    hold_jobs_count: number;
    closed_jobs_count: number;
    resumes_count: number;
  };
}

export interface ICardDetails {
  cardIcon: string;
  count: number;
  title: string;
}

export interface IinitialState {
  totalFiles: ItotalFilesData;
  cardDetails: ICardDetails[];
  loading: string;
}

const initialState: IinitialState = {
  totalFiles: {
    status_message: "",
    data: {
      total_jobs_count: 0,
      active_jobs_count: 0,
      inactive_jobs_count: 0,
      hold_jobs_count: 0,
      closed_jobs_count: 0,
      resumes_count: 0,
    },
  },
  cardDetails: [
    {
      cardIcon: activeJobIcon,
      count: 0,
      title: "Active Jobs",
    },
    {
      cardIcon: jobPostingIcon,
      count: 0,
      title: "Job Posting",
    },
    {
      cardIcon: jobClosedIcon,
      count: 0,
      title: "Jobs Closed",
    },
    {
      cardIcon: profilesIcon,
      count: 0,
      title: "Total Profiles",
    },
  ],
  loading: "",
};

export const totalResumeSlice = createSlice({
  name: "totalFiles",
  initialState,
  reducers: {
    setTotalResumes: (state, action: PayloadAction<ItotalFilesData>) => {
      state.totalFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(callUploadResumeCount.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(callUploadResumeCount.fulfilled, (state, action: any) => {
        state.loading = "Succeded";
        state.cardDetails[0].count = action.payload.data?.active_jobs_count;
        // state.cardDetails[1].count = action.payload.data?.total_jobs_count;
        // state.cardDetails[2].count = action.payload.data?.closed_jobs_count;
        state.cardDetails[3].count = action.payload.data?.resumes_count;
      })
      .addCase(callUploadResumeCount.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export const { setTotalResumes } = totalResumeSlice.actions;
export const getCardDetails = (state: RootState) =>
  state.totalResumesInfo.cardDetails;
export default totalResumeSlice.reducer;
