import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { Grid, Typography } from "@mui/material";
import DetailsCard from "../DetailsCard";
import { Column, AssistoTable } from "../../../../widgets/Table";
import { Stack } from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ThemeProvider } from "@emotion/react";
import theme from "../../../../widgets/Theme/theme";

export interface ITableData {
  title: string;
  tableColumn: Column[];
  tableRows: any[];
  defaultMessage: string;
  headerFontSize: string;
}

const JdColumns: Column[] = [
  { id: "JobId", label: "Job Id", minWidth: 70 },
  { id: "JobTitle", label: "Job Title", minWidth: 100 },
  {
    id: "JobLocation",
    label: "Job Location",
    minWidth: 120,
  },
  {
    id: "Experience",
    label: "Experience",
  },
  {
    id: "MustHaveSkills",
    label: "Must have skills",
    minWidth: 150,
  },
  {
    id: "NiceToHaveSkills",
    label: "Nice to have skills",
    minWidth: 150,
  },
  {
    id: "Vacancy",
    label: "Vacancy",
    minWidth: 150,
  },
];
const applicantColumns: Column[] = [
  { id: "Id", label: "Id", minWidth: 40 },
  { id: "ApplicantName", label: "Applicant Name", minWidth: 140 },
  {
    id: "JobLocation",
    label: "Location",
    minWidth: 120,
  },
  {
    id: "Experience",
    label: "Experience",
  },
  {
    id: "MustHaveSkills",
    label: "Must have skills",
    minWidth: 150,
  },
  {
    id: "NiceToHaveSkills",
    label: "Nice to have skills",
    minWidth: 150,
  },
  {
    id: "Vacancy",
    label: "Vacancy",
    minWidth: 150,
  },
];

const tableData: ITableData[] = [
  {
    title: "HOME_PAGE.JD_TABLE_TITLE",
    tableColumn: JdColumns,
    tableRows: [],
    headerFontSize: "12px",
    defaultMessage: "HOME_PAGE.PLEASE_CREATE_JD",
  },
  {
    title: "HOME_PAGE.APPLICANT_TABLE_TITLE",
    tableColumn: applicantColumns,
    tableRows: [],
    headerFontSize: "12px",
    defaultMessage: "HOME_PAGE.PLEASE_UPLOAD_RESUMES",
  },
];

const DashBoardTable = () => {
  const { t } = useTranslation(NAMESPACE.HOME);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={3}
        mt={1}
        px={{ lg: 4, xl: 0 }}
        pr={{ lg: 0, xl: 4 }}
      >
        {tableData.map((data: ITableData, index: number) => (
          <Grid item xs={12} sm={12} md={10} lg={6} key={index}>
            <Stack py={4} px={2} className="hire-assisto__white-bg">
              <div>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h3" pt={1} pb={4}>
                    {t(data.title)}
                  </Typography>
                  <OpenInNewIcon />
                </Stack>
              </div>

              <AssistoTable
                rows={data.tableRows}
                columns={data.tableColumn}
                headerFontSize={data.headerFontSize}
                defaultMessage={t(data.defaultMessage)}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};
export default DashBoardTable;
