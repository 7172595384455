import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { CategoryThunkToken, ICategoryResponse } from "../thunks/categoryThunk";

export interface ICountryLocationProps {
  categoryInfo: ICategoryResponse;
  loading: string;
}

const initialState: ICountryLocationProps = {
  categoryInfo: {
    status_message: "",
    data: [],
  },
  loading: "",
};

const CategoryInfoSlice = createSlice({
  name: "categoryInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<ICategoryResponse>) => {
      state.categoryInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CategoryThunkToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(CategoryThunkToken.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.categoryInfo = action.payload;
      })
      .addCase(CategoryThunkToken.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default CategoryInfoSlice.reducer;
export const { setStateInfo } = CategoryInfoSlice.actions;
export const categoryData = (state: RootState) =>
  state.getAllCategoryList.categoryInfo.data;
