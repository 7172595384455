import { createAsyncThunk } from "@reduxjs/toolkit";
import { Post } from "../utils/apiServices";

export interface IcreateJdResponse {
  status_message: string;
  data: IcreateJdData;
}

export interface IcreateJdData {
  id: number;
  create_status: string;
}

const CreateJdEndpoint = "job_description/create";

export const createJdThunk = createAsyncThunk(
  "createJd/createJdFile",

  async (payload: any) => {
    try {
      console.log(payload, "payload");
      const response = await Post<IcreateJdResponse, any>(
        CreateJdEndpoint,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
