import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface IStateInfoResponse {
  id: string;
  name: string;
}

export const StateLocationToken = createAsyncThunk(
  "state/stateInfo",
  async (countryId: string) => {
    const StateLocationEndPoint = `http://openapis.nspenvision.com/location/?fields=id,name&countryId=${countryId}`;
    try {
      const response = await Get<IStateInfoResponse[], undefined>(
        StateLocationEndPoint,
        undefined
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
