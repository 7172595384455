import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import {
  ISpecializationResponse,
  SpecializationThunk,
} from "../thunks/specializationThunk";

export interface IRolesProps {
  specilizationInfo: ISpecializationResponse;
  loading: string;
}

const initialState: IRolesProps = {
  specilizationInfo: {
    status_message: "",
    data: [],
  },
  loading: "",
};

const SpecializationInfoSlice = createSlice({
  name: "specializationInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<ISpecializationResponse>) => {
      state.specilizationInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SpecializationThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(SpecializationThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.specilizationInfo = action.payload;
      })
      .addCase(SpecializationThunk.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default SpecializationInfoSlice.reducer;
export const { setStateInfo } = SpecializationInfoSlice.actions;
export const SpecializationData = (state: RootState) =>
  state.getAllSpecializationList.specilizationInfo.data;
