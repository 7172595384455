import { createAsyncThunk } from "@reduxjs/toolkit";
import { Post } from "../utils/apiServices";
import { uploadProgressStatus } from "./uploadProgressStatusThunk";

export interface IUploadFileResponse {
  status_message: string;
  data: IuploadFileData;
}

export interface IuploadFileData {
  job_status_id: number;
  total_files: number;
  background_status: string;
}

const UploadResumesEndpoint = "/resume/upload_resumes";

export const callUploadResumes = createAsyncThunk(
  "uploadResumes/files",

  async (payload: any, thunkAPI) => {
    try {
      const response = await Post<IUploadFileResponse, any>(
        UploadResumesEndpoint,
        payload.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onProgressUpdate: payload.progress,
        },
        false
      );
      thunkAPI.dispatch(uploadProgressStatus(response.data.data.job_status_id));
      return response;
    } catch (error) {
      throw error;
    }
  }
);
