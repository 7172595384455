import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import ResumesIcon from "../../assets/images/resumes-list.svg";
import { ThemeProvider } from "styled-components";
import theme from "../../widgets/Theme/theme";
import { AssistoTable, Column } from "../../widgets/Table";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { ITableData } from "../Dashboard/Components/Table";
import { useEffect, useState } from "react";
import AssistoSearch from "../../widgets/AssistoSearchField";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AssistoButton } from "../../widgets/Button";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getresumesPage } from "../../thunks/resumesPageListThunk";
import {
  GetCompleteResumeData,
  GetTotalResumeData,
} from "../../reducers/resumesPageListReducer";
import AssistoToolTip, { Position } from "../../widgets/AssistoToolTip";
import { isDefined } from "../../utils/helper";
import { loggedInUserPermissions } from "../../utils/permissionManagement";
import { forbiddenPage } from "../Routes/routes";
import { useNavigate } from "react-router-dom";
import Pagination from "../../widgets/Pagination";

const JdColumns: Column[] = [
  { id: "id", label: "Applicant No", minWidth: 50 },
  { id: "resume_name", label: "Applicant Name", minWidth: 150 },
  {
    id: "email",
    label: "Email Address",
    minWidth: 150,
  },
  {
    id: "mobile_number",
    label: "Mobile",
    minWidth: 150,
  },
  {
    id: "total_years_of_experience",
    label: "Experience",
    minWidth: 100,
  },
  {
    id: "job_source",
    label: "Source",
    minWidth: 150,
  },
];

function ResumesPage() {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [rowsPerPageNumber, setRowsPerPageNumber] = useState<number>(10);
  const { t } = useTranslation(NAMESPACE.RESUMESLIST);
  const [totalSelected, setTotalSelected] = useState(false);
  const [toggleSelected, setToggleSelected] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const CompleteResumeAllData = useSelector(GetCompleteResumeData);
  const totalResumeCount = useSelector(GetTotalResumeData);
  const navigate = useNavigate();

  const tableData: ITableData[] = [
    {
      title: "HOME_PAGE.JD_TABLE_TITLE",
      tableColumn: JdColumns,
      tableRows: CompleteResumeAllData,
      headerFontSize: "14px",
      defaultMessage: "HOME_PAGE.PLEASE_CREATE_JD",
    },
  ];

  useEffect(() => {
    if (
      isDefined(loggedInUserPermissions) &&
      loggedInUserPermissions.includes("VIEW_RESUMES")
    ) {
      dispatch(
        getresumesPage({
          page: currentPageNumber,
          pageSize: Number(rowsPerPageNumber),
        })
      );
    } else {
      navigate(forbiddenPage);
    }
  }, [currentPageNumber, rowsPerPageNumber]);

  const onPageNumberClick = (clickedPageNumber: number) => {
    setCurrentPageNumber(clickedPageNumber);
  };

  const onRowsPerPageChange = (value: number) => {
    setRowsPerPageNumber(value);
    setCurrentPageNumber(1);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box width={"98%"} my={4}>
          <Paper>
            <Stack p={4} direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                <img width={"22%"} src={ResumesIcon} alt="resumesIcon" />
                <Stack direction={"column"} width={"100%"}>
                  <Typography
                    variant="h5"
                    fontWeight={"var(--font-weight-500)"}
                  >
                    {t("RESUMES_PAGE.RESUMES_LIST")}
                  </Typography>
                  {toggleSelected && (
                    <Typography mt={0}>
                      {t("RESUMES_PAGE.NUMBER_OF_EMAILS_SELECTED")}
                      {totalSelected}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={3}>
                <AssistoSearch />
                <FileUploadOutlinedIcon fontSize="medium" />
                <TuneOutlinedIcon fontSize="medium" />
                <FilterAltIcon fontSize="medium" />
                <AssistoButton
                  name="Send Email"
                  startIcon={<ForwardToInboxIcon />}
                  buttonVarient="contained"
                  isDisable
                />
              </Stack>
            </Stack>
          </Paper>

          <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Stack className="hire-assisto__white-bg">
                  <AssistoTable
                    rows={CompleteResumeAllData?.map((row, index: number) => {
                      return {
                        id:
                          rowsPerPageNumber * (currentPageNumber - 1) +
                          index +
                          1,
                        resume_name:
                          row.resume_name.length > 15 ? (
                            <AssistoToolTip
                              position={Position.Top}
                              title={row.resume_name}
                              content={row.resume_name
                                .substring(0, 25)
                                .concat("...")}
                            />
                          ) : (
                            row.resume_name
                          ),
                        email: row.email,
                        mobile_number:
                          row.mobile_number.length > 2 ? (
                            <AssistoToolTip
                              position={Position.Top}
                              title={row.mobile_number.join(" , ")}
                              content={row.mobile_number
                                .slice(0, 1)
                                .join(",")
                                .concat("...")}
                            />
                          ) : (
                            row.mobile_number
                          ),
                        total_years_of_experience:
                          row.total_years_of_experience,
                        job_source: row.job_source,
                      };
                    })}
                    columns={JdColumns}
                    defaultMessage={t("RESUMES_PAGE.NO_DATA_AVAILABLE")}
                    headerCheckBoxNeeded={true}
                    headerFontSize={"14px"}
                    dataCheckBoxNeeded={true}
                    isPaginationRequired={false}
                  />
                  <Stack my={2}>
                    <Pagination
                      totalItems={totalResumeCount}
                      currentPageNumber={currentPageNumber}
                      rowsPerPage={rowsPerPageNumber}
                      onPageNumberClick={onPageNumberClick}
                      onRowsPerPageChange={onRowsPerPageChange}
                      rowsPerPageList={[10, 25, 50, "All"]}
                    />
                  </Stack>
                </Stack>
              </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default ResumesPage;
