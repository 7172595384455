/* eslint-disable react-hooks/exhaustive-deps */
import hireAssistoLogo from "../../assets/images/assisto-logo.svg";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  CssBaseline,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotificationsNoneSharpIcon from "@mui/icons-material/NotificationsNoneSharp";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import { useDispatch, useSelector } from "react-redux";
import { loginInfoData } from "../../reducers/userInfoReducer";
import React, { useEffect, useState } from "react";
import ProfileInfo from "../../assets/images/profile-info.svg";
import LogoutIcon from "../../assets/images/logout.svg";
import { ThemeProvider } from "styled-components";
import theme from "../Theme/theme";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { assisto_qa_page } from "../../utils/constants";
import { callVerifyToken } from "../../thunks/userinfoThunk";
import { AppDispatch } from "../../store/store";
import {
  TotalNumberOfFilesUploaded,
  getUploadApiStatus,
} from "../../reducers/uploadResumesReducer";
import {
  getProcessedData,
  getResumeExtractionStatus,
} from "../../reducers/uploadProgressStatusReducer";
import { dashboardPath } from "../../pages/Routes/routes";

export default function Header() {
  const { t } = useTranslation(NAMESPACE.HOME);

  const { userInfo } = useSelector(loginInfoData);
  const getUploadApiStatusLoading = useSelector(getUploadApiStatus);
  const getResumeExtractnStatus = useSelector(getResumeExtractionStatus);
  const processedData = useSelector(getProcessedData);
  const totalNumberOfFilesUploaded = useSelector(TotalNumberOfFilesUploaded);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showResumeUploadSuccess, setShowResumeUploadSuccess] =
    useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch: AppDispatch = useDispatch();

  const isExtractnDone =
    processedData?.data?.total_processed_files === totalNumberOfFilesUploaded;

  const handleLogout = () => {
    setAnchorEl(null);
    if (
      userInfo.company_domain_name !== null &&
      userInfo.company_domain_name !== undefined
    ) {
      window.location.href = `http://${userInfo.company_domain_name}.test.devassisto.com/login?redirect=/`;
    } else {
      window.location.href = `${assisto_qa_page}/verify-email?redirect=/`;
    }
  };
  const handleProfileInfo = () => {
    setAnchorEl(null);
    if (userInfo.role !== "Admin" && userInfo.role !== undefined) {
      window.open(
        `http://${userInfo.company_domain_name}.test.devassisto.com/self-onboarding`,
        "_blank"
      );
    } else {
      window.open(
        `http://${userInfo.company_domain_name}.test.devassisto.com/company-details`,
        "_blank"
      );
    }
  };

  useEffect(() => {
    dispatch(callVerifyToken());
  }, []);

  useEffect(() => {
    if (getUploadApiStatusLoading === "succeeded") {
      setShowResumeUploadSuccess(true);
      setTimeout(() => {
        setShowResumeUploadSuccess(false);
      }, 2000);
    }
  }, [getUploadApiStatusLoading]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", height: "8.5vh" }}>
          <CssBaseline />
          <AppBar component="nav">
            <Toolbar sx={{ pr: 0 }}>
              <Typography
                component="div"
                sx={{
                  flexGrow: 1,
                  pr: 0,
                  display: { xs: "block", sm: "block" },
                  alignItems: "center",
                }}
                data-testid="logo"
              >
                <img src={hireAssistoLogo} alt="logo" className="img-fluid" />
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ flexShrink: 0, marginLeft: "auto" }}
              >
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  data-testid="help-icon"
                >
                  <HelpOutlineSharpIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  data-testid="notification-icon"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsNoneSharpIcon />
                  </Badge>
                </IconButton>
                {getUploadApiStatusLoading === "pending" && (
                  <Stack direction="row" alignItems="center">
                    <CircularProgress size={25} />
                    <Typography ml={1}>
                      {t("HOME_PAGE.UPLOAD_MODAL.UPLOADING")}
                    </Typography>
                  </Stack>
                )}
                {window.location.pathname !== dashboardPath &&
                  getResumeExtractnStatus !== "" &&
                  !isExtractnDone && (
                    <Stack direction="row" alignItems="center">
                      <CircularProgress size={25} />
                      <Typography ml={1}>
                        {t("HOME_PAGE.UPLOAD_MODAL.EXTRACTING")}
                      </Typography>
                    </Stack>
                  )}
                {getUploadApiStatusLoading === "succeeded" &&
                  showResumeUploadSuccess && (
                    <Stack direction="row" alignItems="center">
                      <CheckCircleIcon
                        style={{ fill: "var(--color-success)", fontSize: "30" }}
                      />
                      <Typography ml={1}>
                        {t("HOME_PAGE.UPLOAD_MODAL.UPLOAD_SUCCESS")}
                      </Typography>
                    </Stack>
                  )}

                {window.location.pathname !== dashboardPath &&
                  getResumeExtractnStatus !== "" &&
                  isExtractnDone && (
                    <Stack direction="row" alignItems="center">
                      <CheckCircleIcon
                        style={{ fill: "var(--color-success)", fontSize: "30" }}
                      />
                      <Typography ml={1}>
                        {t("HOME_PAGE.UPLOAD_MODAL.EXTRACTION_SUCCESS")}
                      </Typography>
                    </Stack>
                  )}

                <Box
                  sx={{
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="32"
                    viewBox="0 0 2 32"
                    fill="none"
                    stroke="#A3ACC4"
                    strokeWidth="2"
                    className="hire-assisto__vertical-line"
                  >
                    <path d="M1 0V32" />
                  </svg>
                </Box>

                <Stack
                  direction="column"
                  sx={{ marginLeft: "2", textAlign: "right" }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {userInfo && userInfo.username}
                  </Typography>
                  <Typography variant="subtitle2">
                    {userInfo && userInfo.role}
                  </Typography>
                </Stack>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    data-testid="avatar-text"
                    className="hire-assisto__avatar-text"
                  >
                    {userInfo &&
                      userInfo.username &&
                      userInfo.username.substring(0, 2).toUpperCase()}
                  </Avatar>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  sx={{ ml: 2 }}
                  id="account-menu"
                  data-testid="dropdown-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 40,
                        height: 40,
                        ml: -0.5,
                        mr: 1,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    sx={{ py: 1.5, mt: 0.5, px: 3 }}
                    onClick={handleProfileInfo}
                  >
                    <ListItemIcon>
                      <img src={ProfileInfo} alt="profile-info" />
                    </ListItemIcon>
                    <Typography>
                      {t("HOME_PAGE.ADMIN_PROFILE.PROFILE_INFO")}
                    </Typography>
                  </MenuItem>
                  <MenuItem sx={{ py: 1, px: 3 }} onClick={handleLogout}>
                    <ListItemIcon>
                      <img src={LogoutIcon} alt="logout-icon" />
                    </ListItemIcon>
                    {t("HOME_PAGE.ADMIN_PROFILE.LOGOUT")}
                  </MenuItem>
                </Menu>
              </Stack>
            </Toolbar>
          </AppBar>
        </Box>
      </ThemeProvider>
    </>
  );
}
