import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { IIndustryResponse, IndustryThunkToken } from "../thunks/industryThunk";

export interface ICountryLocationProps {
  industryInfo: IIndustryResponse;
  loading: string;
}

const initialState: ICountryLocationProps = {
  industryInfo: {
    status_message: "",
    data: [],
  },
  loading: "",
};

const IndustryInfoSlice = createSlice({
  name: "stateInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<IIndustryResponse>) => {
      state.industryInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(IndustryThunkToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(IndustryThunkToken.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.industryInfo = action.payload;
      })
      .addCase(IndustryThunkToken.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default IndustryInfoSlice.reducer;
export const { setStateInfo } = IndustryInfoSlice.actions;
export const industryData = (state: RootState) =>
  state.getAllIndustryList.industryInfo.data;
