import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface IIndustryResponse {
  status_message: string;
  data: IIndustryData[];
}

export interface IIndustryData {
  id: number;
  industry_name: string;
}

export const IndustryThunkToken = createAsyncThunk(
  "industry/industryInfo",
  async () => {
    const domain_name = "india";
    const IndustryEndPoint = `job_portal/industries?domain_name=${domain_name}`;
    try {
      const response = await Get<IIndustryResponse, undefined>(
        IndustryEndPoint,
        undefined,
        {},
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
