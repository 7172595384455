export enum NAMESPACE {
  COMMON = "common",
  HOME = "home",
  RESUMESLIST = "resumeslist",
  CREATE_JD = "createjd",
}

export const getCommonNS = () => {
  return { ns: NAMESPACE.COMMON };
};
