import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface ISubSkillResponse {
  status_message: string;
  data: ISubSkillData[];
}

export interface ISubSkillData {
  id: string;
  subskill: string;
  search_type: string,
  skill_id: string;
  created_at: string;
}

const GetAllSubSkillEndpoint = `skills/get_all_subskills`;

export const GetSubSkillThunk = createAsyncThunk("subskill/subskillsInfo", async () => {
  try {
    const response = await Get<ISubSkillResponse, undefined>(
      GetAllSubSkillEndpoint,
      undefined
    );
    return response;
  } catch (error) {
    throw error;
  }
});