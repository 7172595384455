import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { callVerifyToken, IUserInfoResponse } from "../thunks/userinfoThunk";

export interface InitialStateDataProps {
  userInfo: IUserInfoResponse;
  loading: string;
}

const initialState: InitialStateDataProps = {
  userInfo: {
    username: "",
    user_email: "",
    company_domain_name: "",
    role: "",
    privileges: [""],
  },
  loading: "",
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setLoginInfo: (state, action: PayloadAction<IUserInfoResponse>) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(callVerifyToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(callVerifyToken.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.userInfo = action.payload;

        if (window.location.pathname === "/") {
          window.location.href = "/dashboard";
        }
      })
      .addCase(callVerifyToken.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default userInfoSlice.reducer;
export const { setLoginInfo } = userInfoSlice.actions;
export const loginInfoData = (state: RootState) => state.loginInfo;
