import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface ILocationInfoResponse {
  id: string;
  name: string;
}
const CountryLocationEndPoint = `http://openapis.nspenvision.com/location/?fields=id,name`;

export const CountryLocationToken = createAsyncThunk(
  "country/contryInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await Get<ILocationInfoResponse[], undefined>(
        CountryLocationEndPoint,
        undefined,
        {},
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
