import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface ICurrencyResponse {
  cc: string;
}
const currencyEndPoint = `http://openapis.nspenvision.com/currencies/?fields=cc`;

export const CurrencyTokenApi = createAsyncThunk(
  "currency/currencyInfo",
  async () => {
    try {
      const response = await Get<ICurrencyResponse[], undefined>(
        currencyEndPoint,
        undefined,
        {},
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
