import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface ISpecializationResponse {
  status_message: string;
  data: ISpecializationData[];
}

export interface ISpecializationData {
  id: number;
  specialization_name: string;
}

export const SpecializationThunk = createAsyncThunk(
  "specialization/specializationToken",
  async (degreeId: number) => {
    const domain_name = "india";
    const SpecializationEndPoint = `job_portal/degrees/${degreeId}/specializations?domain_name=${domain_name}`;
    try {
      const response = await Get<ISpecializationResponse, undefined>(
        SpecializationEndPoint,
        undefined
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
