import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface ISkillResponse {
  status_message: string;
  data: ISkillData[];
}

export interface ISkillData {
  id: string;
  skill: string;
  created_at: string;
}

const GetSkillEndpoint = `skills/get_all_skills`;

export const GetSkillThunk = createAsyncThunk("skill/skillInfo", async () => {
  try {
    const response = await Get<ISkillResponse, undefined>(
      GetSkillEndpoint,
      undefined
    );
    return response;
  } catch (error) {
    throw error;
  }
});
