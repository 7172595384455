import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getresumesPage } from "../thunks/resumesPageListThunk";
import { RootState } from "../store/store";

export interface IResumesList {
  status_message: string;
  data: IData;
}

export interface IData {
  resumes_details_list: IResumesDetailsList[];
  page: number;
  pagesize: number;
  resumes_count: number;
}

export interface IResumesDetailsList {
  id: number;
  resume_store_path: string;
  resume_name: string;
  name_of_candidate: string;
  resume_skill_list: string[];
  matched_jds: string[];
  current_designation: string;
  total_years_of_experience: string;
  working_domain: string;
  qualification: string;
  speaking_language: SpeakingLanguage;
  github_link: string;
  linkedin_link: string;
  certification: Certification;
  awards: Awards;
  date_of_birth: string;
  mobile_number: string[];
  email: string;
  current_company: string;
  salary: number;
  current_location: string;
  preferred_location: string;
  notice_period: string;
  availability_for_interview: string;
  uploaded_at: string;
  extracted_at: string;
  updated_at: string;
  job_source: string;
  extras: Extras;
}

export interface SpeakingLanguage {}

export interface Certification {}

export interface Awards {}

export interface Extras {}

export interface IresumeListresponse {
  resumeData: IResumesList;
  loading: string;
}

const initialState: IresumeListresponse = {
  resumeData: {
    status_message: "",
    data: {
      resumes_details_list: [],
      page: 0,
      pagesize: 0,
      resumes_count: 0,
    },
  },
  loading: "",
};

export const resumesListSlice = createSlice({
  name: "resumesList",
  initialState,
  reducers: {
    setResumeList: (state, action: PayloadAction<IResumesList>) => {
      state.resumeData.status_message = action.payload.status_message;
      state.resumeData.data = action.payload.data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getresumesPage.pending, (state) => {
        state.loading = "Pending";
      })
      .addCase(getresumesPage.fulfilled, (state, action: any) => {
        state.loading = "Succeded";
        state.resumeData = action.payload;
      })
      .addCase(getresumesPage.rejected, (state, action: any) => {
        state.loading = "Failed";
      });
  },
});

export const { setResumeList } = resumesListSlice.actions;
export const GetCompleteResumeData = (state: RootState) =>
  state.getAllResumeslist.resumeData.data.resumes_details_list;

export const GetTotalResumeData = (state: RootState) =>
  state.getAllResumeslist.resumeData.data.resumes_count;

export default resumesListSlice.reducer;
