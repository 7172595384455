import React from "react";
import { Stack } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import theme from "../../../../widgets/Theme/theme";
import { ThemeProvider } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { ICardDetails } from "../../../../reducers/totalResumesCountReducer";

interface IDetailsCard {
  cardDetails: ICardDetails[];
}

const DetailsCard = (props: IDetailsCard) => {
  const { t } = useTranslation(NAMESPACE.HOME);

  const { cardDetails } = props;

  return (
    <ThemeProvider theme={theme}>
      <div className="card">
        <Grid
          container
          spacing={3}
          mt={1}
          px={{ lg: 4, xl: 0 }}
          pr={{ lg: 0, xl: 4 }}
        >
          {cardDetails.map((data: ICardDetails, i: number) => (
            <Grid key={i} item xs={12} sm={8} md={4} lg={3} xl={3}>
              <div
                style={{
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "8px",
                }}
              >
                <Stack
                  direction="row"
                  spacing={{ md: 1, lg: 1, xl: 4 }}
                  py={4}
                  px={{ md: 1, lg: 1, xl: 3 }}
                  alignItems="center"
                >
                  <div className="card__image">
                    <img src={data.cardIcon} alt="icons" />
                  </div>
                  <div>
                    <Typography variant="h1">{data.count}</Typography>
                    <Typography variant="subtitle1">{data.title}</Typography>
                  </div>
                </Stack>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default DetailsCard;
