import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";
import { RootState } from "../store/store";
import { callUploadResumeCount } from "./totalResumesCountThunk";

export interface IUploadProgressStatus {
  status_message: string;
  data: IData;
}

export interface IData {
  total_processed_files: number;
  duplicate_resumes: number;
  invalid_resumes: number;
  extracted_resumes: number;
  failed_extracted_resumes: number;
  files_logs_list: ITaskResultsList[];
}

export interface ITaskResultsList {
  id: number;
  task_type: string;
  resource_type: string;
  status: string;
  status_message: string;
  fail_reason: string;
  created_by: string;
  created_at: string;
  job_status_id: number;
}

export const uploadProgressStatus = createAsyncThunk(
  "uploadsuccess/UploadProgressStatus",
  async (jobId: number, thunkAPI) => {
    if (jobId !== undefined && jobId > 0) {
      const UploadProgressStatusEndpoint = `resume/logs/${jobId}?page=1&pageSize=10`;
      try {
        const response = await Get<IUploadProgressStatus, any>(
          UploadProgressStatusEndpoint,
          undefined,
          {},
          false
        );
        const state = thunkAPI.getState() as RootState;
        const totalFiles = state.resumesInfo.files.data.total_files;
        const totalProcessedFiles =
          state.uploadProgressStatus.uploadStatusData.data
            .total_processed_files;

        if (totalProcessedFiles !== totalFiles) {
          setTimeout(() => {
            thunkAPI.dispatch(uploadProgressStatus(jobId));
          }, 3000);
        } else {
          thunkAPI.dispatch(callUploadResumeCount());
        }

        return response;
      } catch (error) {
        throw error;
      }
    }
  }
);
