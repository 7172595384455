import Chip from "@mui/material/Chip";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { ISkillData } from "../../thunks/getSkillthunk";
import { SyntheticEvent } from "react";

export interface IAssistoCustomAddInputField {
  required: boolean;
  id?:string;
  label: string;
  placeholder: string;
  getValues: string[];
  skillsList?: ISkillData[];
  error?: boolean;
  helperText?: string;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => void;
}

export default function AssistoCustomAddInputField(
  props: IAssistoCustomAddInputField
) {
  const { skillsList } = props;

  return (
    <Stack>
      <Autocomplete
        multiple
        id="tags-filled"
        options={(skillsList || []).map((option) => option.skill)}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) => {
          return props.getValues.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ));
        }}
        onChange={props.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            id={props.id}
            label={props.label}
            required={props.required}
            placeholder={props.placeholder}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    </Stack>
  );
}
