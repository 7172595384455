import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface IRolesResponse {
  status_message: string;
  data: IRolesData[];
}

export interface IRolesData {
  id: string;
  role_name: string;
}

export const RolesThunkToken = createAsyncThunk(
  "roles/rolesToken",
  async (catId: string) => {
    const domain_name = "india";
    const RolesEndPoint = `job_portal/categories/${catId}/roles?domain_name=${domain_name}`;
    try {
      const response = await Get<IRolesResponse, undefined>(
        RolesEndPoint,
        undefined
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
