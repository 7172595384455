import { createAsyncThunk } from "@reduxjs/toolkit";
import {Post} from "../utils/apiServices";

export interface IAddSkillsResponse {
        "status_message": string,
        "data": {
          "message": string,
          "skills_added": string[],
          "subskills_added": string[]
        }
}

export interface IAddSkillRequest {
  data: {
    skill: string;
    subskill: string[];
  }[];
}

export interface IAddSkillData {
        "skill": string,
        "subskill": string[]
}

const addSkillsEndPoint = "skills/add_skill";

export const callAddSkill = createAsyncThunk(
  "skill/addSkill",
  async (data: IAddSkillRequest) => {
    try {
      const response = await Post<IAddSkillsResponse, IAddSkillRequest>(
        addSkillsEndPoint,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
