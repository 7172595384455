import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uploadProgressStatus } from "../thunks/uploadProgressStatusThunk";
import { RootState } from "../store/store";

interface ITemp {
  uploadStatusData: IUploadProgressStatus;
  loading: string;
}

export interface IUploadProgressStatus {
  status_message: string;
  data: IData;
}

export interface IData {
  total_processed_files: number;
  duplicate_resumes: number;
  invalid_resumes: number;
  extracted_resumes: number;
  failed_extracted_resumes: number;
  files_logs_list: ITaskResultsList[];
}

export interface ITaskResultsList {
  id: number;
  task_type: string;
  resource_type: string;
  status: string;
  status_message: string;
  fail_reason: string;
  created_by: string;
  created_at: string;
  job_status_id: number;
}

let initialState: ITemp = {
  uploadStatusData: {
    status_message: "",
    data: {
      total_processed_files: 0,
      duplicate_resumes: 0,
      invalid_resumes: 0,
      extracted_resumes: 0,
      failed_extracted_resumes: 0,
      files_logs_list: [],
    },
  },
  loading: "",
};

const UploadProgressStatusSlice = createSlice({
  name: "uploadProgress",
  initialState,
  reducers: {
    setUploadProgressStatus: (
      state,
      action: PayloadAction<IUploadProgressStatus>
    ) => {
      state.uploadStatusData.status_message = action.payload.status_message;
      state.uploadStatusData.data = action.payload.data;
    },
    resetUploadProgressStatusState: (state) => {
      return { ...state, loading: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadProgressStatus.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(uploadProgressStatus.fulfilled, (state, action: any) => {
        state.loading = "succeeded";
        state.uploadStatusData = action.payload;
      })
      .addCase(uploadProgressStatus.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export const { setUploadProgressStatus, resetUploadProgressStatusState } =
  UploadProgressStatusSlice.actions;
export const getProcessedData = (state: RootState) =>
  state.uploadProgressStatus.uploadStatusData;
export const getResumeExtractionStatus = (state: RootState) =>
  state.uploadProgressStatus.loading;
export default UploadProgressStatusSlice.reducer;
