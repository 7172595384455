import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export enum Position {
  Top = "top",
  Bottom = "bottom",
  Left = "left",
  Right = "right",
  BottomRight = "bottom-start",
  BottomEnd = "bottom-end",
  RightStart = "right-start",
  RightEnd = "right-end",
  LeftStart = "left-start",
  LeftEnd = "left-end",
  TopStart = "top-start",
  TopEnd = "top-end",
}

export interface IAssistoToolTip {
  content: string;
  title: string;
  position: Position;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--color-actions)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--color-dark-purple)",
  },
}));

export default function AssistoToolTip(props: IAssistoToolTip) {
  return (
    <div>
      <BootstrapTooltip title={props.title} placement={props.position}>
        <Typography>{props.content}</Typography>
      </BootstrapTooltip>
    </div>
  );
}
