import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { IRolesResponse, RolesThunkToken } from "../thunks/rolesThunk";

export interface IRolesProps {
  rolesInfo: IRolesResponse;
  loading: string;
}

const initialState: IRolesProps = {
  rolesInfo: {
    status_message: "",
    data: [],
  },
  loading: "",
};

const RolesInfoSlice = createSlice({
  name: "categoryInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<IRolesResponse>) => {
      state.rolesInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RolesThunkToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(RolesThunkToken.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.rolesInfo = action.payload;
      })
      .addCase(RolesThunkToken.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default RolesInfoSlice.reducer;
export const { setStateInfo } = RolesInfoSlice.actions;
export const RolesData = (state: RootState) =>
  state.getAllRolesList.rolesInfo.data;
