import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import {
  CountryLocationToken,
  ILocationInfoResponse,
} from "../thunks/countryLocationThunk";

export interface ICountryLocationProps {
  contryInfo: ILocationInfoResponse[];
  loading: string;
}

const initialState: ICountryLocationProps = {
  contryInfo: [],
  loading: "",
};

const CountryInfoSlice = createSlice({
  name: "contryInfo",
  initialState,
  reducers: {
    setCountryInfo: (state, action: PayloadAction<ILocationInfoResponse[]>) => {
      state.contryInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CountryLocationToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(CountryLocationToken.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.contryInfo = action.payload;
      })
      .addCase(CountryLocationToken.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default CountryInfoSlice.reducer;
export const { setCountryInfo } = CountryInfoSlice.actions;
export const countryData = (state: RootState) =>
  state.getAllCountryList.contryInfo;
