import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import { ThemeProvider } from "styled-components";
import theme from "../Theme/theme";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";

interface StatusCardProps {
  type: "success" | "InProgress" | "error";
  message: string;
  closeButtonNeeded: boolean;
  showStatusCard: boolean;
  onCloseStatusCard: () => void;
}

function StatusCard({
  type,
  message,
  closeButtonNeeded,
  showStatusCard,
  onCloseStatusCard,
}: StatusCardProps) {
  const { t } = useTranslation(NAMESPACE.HOME);

  const backgroundColor =
    type === "success"
      ? "var(--color-success)"
      : type === "InProgress"
      ? "var(--color-dark-purple)"
      : "var(--color-dark-red)";
  const text =
    type === "success"
      ? t("HOME_PAGE.UPLOAD_MODAL.SUCCESS")
      : type === "InProgress"
      ? t("HOME_PAGE.UPLOAD_MODAL.EXTRACTING_RESUMES")
      : t("HOME_PAGE.UPLOAD_MODAL.ERROR");

  return (
    <>
      <ThemeProvider theme={theme}>
        {showStatusCard && (
          <Stack
            style={{ backgroundColor }}
            direction={"column"}
            width={"450px"}
            px={2}
            py={2}
            borderRadius={"8px"}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"} gap={1.5}>
                {type === "success" ? (
                  <CheckCircleIcon style={{ fill: "var(--color-white)" }} />
                ) : type === "InProgress" ? (
                  <span className="spinner"></span>
                ) : (
                  <ErrorIcon style={{ fill: "var(--color-white)" }} />
                )}
                <Stack>
                  <Typography
                    color={"var(--color-white)"}
                    fontWeight={"var(--font-weight-600)"}
                  >
                    {text}
                  </Typography>
                  <Typography
                    mt={1}
                    fontWeight={"var(--font-weight-400)"}
                    className="status__card__text"
                    color={"var(--color-white)"}
                    variant="h5"
                  >
                    {message}
                  </Typography>
                </Stack>
              </Stack>
              {closeButtonNeeded ? (
                <CloseIcon
                  onClick={onCloseStatusCard}
                  style={{ fill: "var(--color-white)" }}
                />
              ) : (
                false
              )}
            </Stack>
          </Stack>
        )}
      </ThemeProvider>
    </>
  );
}

export default StatusCard;
