import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import {
  GetSubSkillThunk,
  ISubSkillResponse,
} from "../thunks/getSubSkillThunk";

export interface ISubSkillProps {
  getSubSkillInfo: ISubSkillResponse;
  loading: string;
}

const initialState: ISubSkillProps = {
  getSubSkillInfo: {
    status_message: "",
    data: [
      { id: "", subskill: "", search_type: "", skill_id: "", created_at: "" },
    ],
  },
  loading: "",
};

const GetSubSkillSlice = createSlice({
  name: "subskillInfo",
  initialState,
  reducers: {
    setSubSkillInfo: (state, action: PayloadAction<ISubSkillResponse>) => {
      state.getSubSkillInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetSubSkillThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(GetSubSkillThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.getSubSkillInfo = action.payload;
      })
      .addCase(GetSubSkillThunk.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default GetSubSkillSlice.reducer;
export const { setSubSkillInfo } = GetSubSkillSlice.actions;
export const subskillData = (state: RootState) =>
  state.getAllSubSkillsList.getSubSkillInfo.data;
