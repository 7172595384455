import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { CityLocationToken, ICityInfoResponse } from "../thunks/cityLocation";

export interface ICountryLocationProps {
  cityInfo: ICityInfoResponse[];
  loading: string;
}

const initialState: ICountryLocationProps = {
  cityInfo: [],
  loading: "",
};

const CityInfoSlice = createSlice({
  name: "cityInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<ICityInfoResponse[]>) => {
      state.cityInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CityLocationToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(CityLocationToken.fulfilled, (state, action: any) => {
        state.loading = "succeeded";
        state.cityInfo = action.payload;
      })
      .addCase(CityLocationToken.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default CityInfoSlice.reducer;
export const { setStateInfo } = CityInfoSlice.actions;
export const cityData = (state: RootState) => state.getAllCityList.cityInfo;
