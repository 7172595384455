import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";

export enum Status {
  Success = "success",
  Warning = "warning",
  Error = "error",
}

export enum Variant {
  Filled = "filled",
  Standard = "standard",
  Outlined = "outlined",
}

enum VerticalPosition {
  Top = "top",
  Bottom = "bottom",
}

enum HorizontalPosition {
  Left = "left",
  Right = "right",
  Center = "center",
}

interface IStyle {
  alignItems: string;
}

interface ISnackBar {
  open: boolean;
  handleClose?: (event: React.SyntheticEvent | Event, reason?: string) => void;
  message: string;
  status: Status;
  variant: Variant;
  autoHideDuration?: number;
  anchorOrigin?: {
    vertical: VerticalPosition;
    horizontal: HorizontalPosition;
  };
  style?: IStyle;
}

export default function SnackBar(props: ISnackBar) {
  const {
    open,
    handleClose,
    message,
    status,
    variant,
    autoHideDuration = 6000,
    anchorOrigin = {
      vertical: VerticalPosition.Top,
      horizontal: HorizontalPosition.Right,
    },
    style = { alignItems: "center" },
  } = props;

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={props.handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      sx={{ top: "65px", right: "0px" }}
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      action={action}
    >
      <MuiAlert
        sx={style}
        elevation={6}
        variant={variant}
        onClose={handleClose}
        severity={status}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
