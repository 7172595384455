import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import AssistoTextFiled from "../../widgets/AssistoTextField";
import {
  alphaNumericWithSpaceOnlySpecialCharPattern,
  alphabetsNumbersWithDotAndHash,
  alphabetsWithFiveThousandChar,
  experienceFieldWithDecimalChar,
} from "../../utils/regExp";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import { ThemeProvider } from "styled-components";
import theme from "../../widgets/Theme/theme";
import AssistoCustomAddInputField from "../../widgets/AssistoCustomAddInputField";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { AssistoButton } from "../../widgets/Button";
import { useDispatch, useSelector } from "react-redux";
import { ISkillData } from "../../thunks/getSkillthunk";
import { skillData } from "../../reducers/getSkillsReducer";
import AssistantIcon from "@mui/icons-material/Assistant";
import { ILocationInfoResponse } from "../../thunks/countryLocationThunk";
import {
  IStateInfoResponse,
  StateLocationToken,
} from "../../thunks/stateLocationThunk";
import {
  CityLocationToken,
  ICityInfoResponse,
} from "../../thunks/cityLocation";
import { cityData } from "../../reducers/cityLocationReducer";
import { stateData } from "../../reducers/stateLocationReducer";
import { countryData } from "../../reducers/countryLocationReducer";
import { AppDispatch, RootState } from "../../store/store";
import { createJdThunk } from "../../thunks/createJdThunk";
import SnackBar, { Status, Variant } from "../../widgets/Toaster";
import {
  createJdApiLoading,
  resetCreateJdState,
} from "../../reducers/createJdReducer";
import { FULL_TIME, SHORT_JD } from "../../utils/constants";
import { jobsPage } from "../Routes/routes";
import { useNavigate } from "react-router-dom";
import { subskillData } from "../../reducers/getSubSkillReducer";
import { ISubSkillData } from "../../thunks/getSubSkillThunk";
import { IAddSkillRequest, callAddSkill } from "../../thunks/skillsThunk";

interface Ifields {
  job_posting_type: string;
  jobTitleField: string;
  status: string;
  jobDescription: string | null;
  fromExpField: number;
  toExpField: number;
  country: ILocationInfoResponse | null;
  state: IStateInfoResponse | null;
  city: ICityInfoResponse | null;
  mustHaveSkill: string[];
  goodToHaveSkill: string[];
}

function ShortJobDescription() {
  const { t } = useTranslation(NAMESPACE.CREATE_JD);
  const dispatch: AppDispatch = useDispatch();
  const [toaster, setToaster] = useState({
    open: false,
    message: "",
    status: Status.Success,
    variant: Variant.Filled,
  });

  const navigate = useNavigate();

  const createJdApiLoadingStatus = useSelector(createJdApiLoading);

  const shortJdInitialData = {
    job_posting_type: SHORT_JD,
    jobTitleField: "",
    status: "Active",
    jobDescription: null,
    fromExpField: 0,
    toExpField: 0,
    country: null,
    state: null,
    city: null,
    mustHaveSkill: [],
    goodToHaveSkill: [],
  };

  const initialErrorData = {
    jobTitleField: {
      mandatory: false,
      patternFail: false,
    },
    fromExpField: {
      mandatory: false,
      patternFail: false,
      lessThan: false,
    },
    toExpField: {
      mandatory: false,
      patternFail: false,
      greaterThan: false,
    },
    mustHaveSkill: {
      mandatory: false,
      patternFail: false,
    },
    jobDescription: {
      patternFail: false,
    },
    country: {
      mandatory: false,
    },
  };

  const [createJdPage, setCreateJdPage] = useState<Ifields>(shortJdInitialData);

  const [errors, setErrors] = useState(initialErrorData);

  const handleFormField = (
    event: React.ChangeEvent<HTMLInputElement>,
    isMandatoryField: boolean,
    regex?: RegExp
  ) => {
    const { value, id } = event.target;
    setCreateJdPage((formField) => {
      return {
        ...formField,
        [id]: value,
      };
    });

    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [id]: {
          mandatory: isMandatoryField ? value === "" : false,
          patternFail: value !== "" ? !regex?.test(value) : false,
          greaterThan: Number(value) < createJdPage?.fromExpField,
        },
      };
    });
  };

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    const selectedCountryName = e.target.value;
    const tempSelectedCountry =
      countryItems?.find((country) => country.name === selectedCountryName) ??
      null;
    if (tempSelectedCountry !== null) {
      dispatch(StateLocationToken(tempSelectedCountry.id));
    }
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      country: tempSelectedCountry,
    }));
  };

  const handleStateChange = (e: SelectChangeEvent<string>) => {
    const selectedStateName = e.target.value;
    const tempSelectedState =
      stateItems?.find((state) => state.name === selectedStateName) ?? null;
    if (tempSelectedState !== null && createJdPage.country !== null) {
      dispatch(
        CityLocationToken({
          countryId: createJdPage.country?.id,
          stateId: tempSelectedState.id,
        })
      );
    }
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      state: tempSelectedState,
    }));
  };

  const handleCityChange = (e: SelectChangeEvent<string>) => {
    const selectedCityName = e.target.value;
    const tempCityName =
      cityItems?.find((cities) => cities.name === selectedCityName) ?? null;

    setCreateJdPage((prevValue) => ({
      ...prevValue,
      city: tempCityName,
    }));
  };

  const handleTextFieldChange = (
    id: string,
    e: ChangeEvent<HTMLTextAreaElement>,
    isMandatory: boolean,
    regex?: RegExp
  ) => {
    const newTextValue = e.target.value;
    setCreateJdPage((prevValue) => {
      return {
        ...prevValue,
        [id]: newTextValue,
      };
    });
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [id]: {
          mandatory: isMandatory ? newTextValue === "" : false,
          patternFail: !regex?.test(newTextValue),
        },
      };
    });
  };
  const subSkillsData: ISubSkillData[] = useSelector(subskillData);
  const [selectedSkillData, setSelectedSkillData] = useState<ISkillData[]>();
  useEffect(() => {
    const goodToHaveSkills = skillsItemData.filter(
      (skill: any) => !createJdPage.mustHaveSkill.includes(skill.skill)
    );
    setSelectedSkillData(goodToHaveSkills);
  }, [createJdPage.mustHaveSkill]);

  const handleShortJobFormSubmit = () => {
    const jobSkills = createJdPage.mustHaveSkill.map((skilldata: string) =>
      skilldata.toLowerCase()
    );
    const subSkills = subSkillsData.map((subSkill: ISubSkillData) =>
      subSkill.subskill.toLowerCase()
    );
    const nonMatching = jobSkills.filter(
      (jobSkill) => !subSkills.includes(jobSkill)
    );
    if (nonMatching.length > 0) {
      const payload = nonMatching.map((skill) => ({
        skill: skill,
        subskill: [skill],
      }));
      let addSkillData: IAddSkillRequest = {
        data: payload,
      };
      const response = dispatch(callAddSkill(addSkillData));
      handleCreateJd();
    } else {
      handleCreateJd();
    }
  };

  const getSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const validSkills = value.filter((skill) =>
      alphabetsNumbersWithDotAndHash.test(skill)
    );
    let invalidSkills = value.find((skill) => !alphabetsNumbersWithDotAndHash.test(skill));
    const stringArray = value;
    
    if (!invalidSkills) {
      setCreateJdPage((prev) => {
        return {
          ...prev,
          mustHaveSkill: stringArray,
        };
      });
      setErrors({
        ...errors,
        mustHaveSkill: {
          mandatory: false,
          patternFail: false,
        }
    })
    
    } else {
      setCreateJdPage((prev) => ({
        ...prev,
        mustHaveSkill: stringArray,
      }));
      setErrors((prev) => {
        return {
          ...prev,
          mustHaveSkill: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }
  };

  const getGoodToHaveSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    setCreateJdPage((prev) => {
      return {
        ...prev,
        goodToHaveSkill: value,
      };
    });
  };

  const skillsItemData: ISkillData[] = useSelector(skillData);
  const countryItems: ILocationInfoResponse[] = useSelector(countryData);
  const stateItems: IStateInfoResponse[] = useSelector(stateData);
  const cityItems: ICityInfoResponse[] = useSelector(cityData);

  const mandatoryType = (obj?: unknown, num?: any): boolean => {
    return obj !== undefined && obj !== null && obj !== "";
  };

  const patternFailType = (
    value: string | undefined | null | any,
    regex: RegExp | any,
    isMandatory?: boolean
  ): boolean => {
    if (isMandatory) {
      return regex.test(value);
    } else {
      return true;
    }
  };

  const handleCreateJd = () => {
    if (
      mandatoryType(createJdPage.jobTitleField) &&
      patternFailType(
        createJdPage.jobTitleField,
        alphaNumericWithSpaceOnlySpecialCharPattern,
        true
      ) &&
      mandatoryType(createJdPage.fromExpField) &&
      (createJdPage.fromExpField !== null
        ? patternFailType(
            createJdPage.fromExpField,
            experienceFieldWithDecimalChar
          )
        : false) &&
      mandatoryType(createJdPage.toExpField) &&
      (createJdPage.toExpField !== null && createJdPage.fromExpField !== null
        ? patternFailType(
            createJdPage.toExpField,
            experienceFieldWithDecimalChar
          ) || createJdPage.toExpField < createJdPage.fromExpField
        : false) &&
      patternFailType(
        createJdPage.jobDescription,
        alphabetsWithFiveThousandChar,

        false
      ) &&
      createJdPage.mustHaveSkill.length > 0 &&
      mandatoryType(createJdPage.country)
    ) {
      const skillsData = createJdPage.mustHaveSkill
        .map((skill) => {
          return {
            skill: skill,
            ismandatory: true,
          };
        })
        .concat(
          createJdPage.goodToHaveSkill.map((skill) => {
            return {
              skill: skill,
              ismandatory: false,
            };
          })
        );

      const FormData = {
        jd_details: {
          posting_type: SHORT_JD,
          job_title: createJdPage.jobTitleField,
          job_end_date: null,
          job_summary: null,
          job_description: createJdPage.jobDescription,
          industry: null,
          category: null,
          roles: null,
          minimum_experience: Number(createJdPage.fromExpField),
          maximum_experience: Number(createJdPage.toExpField),
          certified_degree_name: null,
          specialization: null,
          currency: null,
          minimum_salary: null,
          maximum_salary: null,
          contract_joining_date: null,

          contract_duration: null,
          contract_billing_rate: null,
          walkin_city_name: null,
          walkin_start_date: null,
          walkin_end_date: null,

          walkin_start_time: null,
          walkin_end_time: null,
          vacancy: null,
          country: createJdPage.country?.name,
          state: createJdPage.state?.name,
          city: createJdPage.city?.name,
          company_name: null,
          about_company: null,
          name: null,
          phone_number: null,
          email: null,
          apply_online_url: null,
          is_short_jd: false,
          status: createJdPage.status,
        },
        jd_skills_details: skillsData,
      };
      dispatch(createJdThunk(FormData));
    } else {
      if (createJdPage?.jobTitleField === "") {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            jobTitleField: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      } else if (
        !patternFailType(
          createJdPage?.jobTitleField,
          alphaNumericWithSpaceOnlySpecialCharPattern
        )
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            jobTitleField: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }
      if (
        createJdPage.jobDescription !== null &&
        !patternFailType(
          createJdPage?.jobDescription,
          alphabetsWithFiveThousandChar
        )
      ) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            jobDescription: {
              patternFail: true,
            },
          };
        });
      }
      if (createJdPage.fromExpField > createJdPage.toExpField) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            toExpField: {
              mandatory: false,
              patternFail: false,
              greaterThan: true,
            },
          };
        });
      }
      if (createJdPage?.country === null) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            country: {
              mandatory: true,
            },
          };
        });
      }
      if (createJdPage?.mustHaveSkill.length === 0) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            mustHaveSkill: {
              mandatory: true,
              patternFail: false,
            },
          };
        });
      } else {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            mustHaveSkill: {
              mandatory: false,
              patternFail: true,
            },
          };
        });
      }

      setToaster((toast) => {
        return {
          ...toast,
          open: true,
          message: t("CREATE_JD.PLEASE_FILL_THE_FIELDS"),
          status: Status.Error,
        };
      });
    }
  };

  const handleCancelCreateJd = () => {
    dispatch(resetCreateJdState());
    setCreateJdPage(shortJdInitialData);
    setErrors(initialErrorData);
    navigate(jobsPage);
  };

  const handleToastClose = () => {
    setToaster((toast) => {
      return {
        ...toast,
        open: false,
        message: "",
      };
    });
  };

  useEffect(() => {
    if (createJdApiLoadingStatus === "succeeded") {
      setCreateJdPage(shortJdInitialData);
      setErrors(initialErrorData);
      setToaster((toast) => {
        return {
          ...toast,
          open: true,
          message: t("CREATE_JD.JOB_CREATED_SUCCESSFULLY"),
          status: Status.Success,
        };
      });
      dispatch(resetCreateJdState());
    }
    if (createJdApiLoadingStatus === "failed") {
      setToaster((toast) => {
        return {
          ...toast,
          open: true,
          message: t("CREATE_JD.FAILED_TO_CREATE_A_JD"),
          status: Status.Error,
        };
      });
    }
  }, [createJdApiLoadingStatus]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackBar {...toaster} handleClose={handleToastClose} />
        <Paper sx={{ py: 4 }}>
          <Grid container px={3.5} gap={3}>
            <Grid item xl={12} lg={12} md={12}>
              <Typography fontSize={"var(--font-size-sm)"}>
                <span style={{ color: "var(--color-dark-red)" }}>
                  {t("CREATE_JD.NOTE")}:
                </span>{" "}
                {t("CREATE_JD.THIS_IS_FOR_INTERNAL_USE_ONLY")}
              </Typography>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
              <AssistoTextFiled
                label="Job Title"
                required
                id="jobTitleField"
                value={createJdPage.jobTitleField}
                errorMessage={
                  errors?.jobTitleField?.mandatory ||
                  errors?.jobTitleField?.patternFail
                }
                helperText={
                  errors?.jobTitleField?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_JOB_TITLE")
                    : errors?.jobTitleField?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                handleChange={(e) => {
                  handleFormField(
                    e,
                    true,
                    alphaNumericWithSpaceOnlySpecialCharPattern
                  );
                }}
              />
            </Grid>
            <Grid
              item
              xl={3.5}
              lg={3.5}
              md={12}
              sm={12}
              display={"grid"}
              alignItems={"center"}
            >
              <Grid item xs={12} xl={12} lg={12} sx={{ margin: "6px 0" }}>
                <Grid container alignItems={"center"} gap={2}>
                  <Grid item xs={3} pl={1}>
                    <Typography>
                      {t("CREATE_JD.EXPERIENCE")}
                      <span style={{ marginLeft: "3px", color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <AssistoTextFiled
                      required
                      errorMessage={
                        errors?.fromExpField?.mandatory ||
                        errors?.fromExpField?.patternFail ||
                        errors?.fromExpField?.lessThan
                      }
                      helperText={
                        errors?.fromExpField?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                          : errors?.fromExpField?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : ""
                      }
                      id="fromExpField"
                      label={t("CREATE_JD.FROM")}
                      value={String(createJdPage.fromExpField) ?? ""}
                      handleChange={(e) => {
                        handleFormField(
                          e,
                          true,
                          experienceFieldWithDecimalChar
                        );
                      }}
                    />
                  </Grid>
                  <Grid item lg={0.25}>
                    <hr />
                  </Grid>
                  <Grid item xs={3.5}>
                    <AssistoTextFiled
                      errorMessage={
                        errors?.toExpField?.mandatory ||
                        errors?.toExpField?.patternFail ||
                        errors?.toExpField?.greaterThan
                      }
                      required
                      helperText={
                        errors?.toExpField?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                          : errors?.toExpField?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : errors.toExpField.greaterThan
                          ? t("CREATE_JD.TO_VALUE_SHOULD_BE_GREATER")
                          : ""
                      }
                      id="toExpField"
                      label={t("CREATE_JD.TO")}
                      value={String(createJdPage.toExpField) ?? ""}
                      handleChange={(e) => {
                        handleFormField(
                          e,
                          true,
                          experienceFieldWithDecimalChar
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <FormControl
                fullWidth
                error={errors?.jobDescription?.patternFail}
              >
                <TextField
                  fullWidth
                  placeholder={t("CREATE_JD.JOB_DESCRIPTION")}
                  multiline
                  id="jobDescription"
                  value={createJdPage.jobDescription ?? ""}
                  rows={3}
                  inputProps={{
                    "data-testid": "jobDescription",
                  }}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    handleTextFieldChange(
                      "jobDescription",
                      e,
                      false,
                      alphabetsWithFiveThousandChar
                    )
                  }
                />
                {createJdPage.jobDescription !== "" &&
                  errors?.jobDescription?.patternFail && (
                    <FormHelperText>
                      {t(
                        "CREATE_JD.PLEASE_ENTER_MINIMUM_FIVE_THOUSAND_CHARACTER"
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <AssistantIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.SKILLS_TITLE")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <AssistoCustomAddInputField
                required
                label={t("CREATE_JD.MUST_HAVE_SKILL")}
                placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                getValues={createJdPage.mustHaveSkill}
                skillsList={skillsItemData}
                id="mustHaveSkill"
                onChange={getSkillsValue}
                error={
                  errors?.mustHaveSkill?.mandatory ||
                  errors?.mustHaveSkill?.patternFail
                }
                helperText={
                  errors?.mustHaveSkill?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_ATLEAST_ONE_MUST_HAVE_SKILL")
                    : errors?.mustHaveSkill?.patternFail
                    ? t("CREATE_JD.PLEASE_REMOVE_INVALID_DATA")
                    : ""
                }
              />
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <AssistoCustomAddInputField
                required={false}
                label={t("CREATE_JD.GOOD_TO_HAVE_SKILL")}
                placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                getValues={createJdPage.goodToHaveSkill}
                skillsList={selectedSkillData}
                onChange={getGoodToHaveSkillsValue}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <FmdGoodIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.JOB_LOCATION")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl
                fullWidth
                error={
                  (createJdPage.country === null ||
                    createJdPage.country?.name === "") &&
                  errors?.country?.mandatory
                }
              >
                <InputLabel>{t("CREATE_JD.COUNTRY")}</InputLabel>
                <Select
                  id="country"
                  value={createJdPage.country?.name ?? ""}
                  label={t("CREATE_JD.COUNTRY")}
                  onChange={handleCountryChange}
                  inputProps={{
                    "data-testid": "country",
                  }}
                >
                  {countryItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {(createJdPage.country === null ||
                  createJdPage.country?.name === "") &&
                  errors?.country?.mandatory && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.STATE")}</InputLabel>
                <Select
                  id="state"
                  value={createJdPage.state?.name ?? ""}
                  label={t("CREATE_JD.STATE")}
                  onChange={handleStateChange}
                  disabled={!createJdPage.country}
                >
                  {stateItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.country && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.CITY")}</InputLabel>
                <Select
                  id="city"
                  value={createJdPage.city?.name ?? ""}
                  label={t("CREATE_JD.CITY")}
                  onChange={handleCityChange}
                  disabled={!createJdPage.country || !createJdPage.state}
                >
                  {cityItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.country && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_COUNTRY_&_STATE")}*
                  </FormHelperText>
                )}
                {createJdPage.country && !createJdPage.state && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_STATE")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Stack direction={"row"} gap={2} mt={2}>
              <AssistoButton
                buttonVarient="contained"
                name="Save"
                buttonClick={handleShortJobFormSubmit}
              />
              <AssistoButton
                buttonVarient="outlined"
                name="Cancel"
                buttonClick={handleCancelCreateJd}
              />
            </Stack>
          </Grid>
        </Paper>
      </ThemeProvider>
    </>
  );
}

export default ShortJobDescription;
