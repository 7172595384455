import { ThemeProvider } from "@emotion/react";
import theme from "../../widgets/Theme/theme";
import { useEffect } from "react";
import { assisto_qa_page } from "../../utils/constants";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { callVerifyToken } from "../../thunks/userinfoThunk";
import LoadingPage from "../../widgets/LoadingPage";
import { useSearchParams } from "react-router-dom";

const HomePage = () => {
  const dispatch: AppDispatch = useDispatch();
  const [urlParams] = useSearchParams();
  const token = urlParams.get("token");

  useEffect(() => {
    if (token !== null && token !== undefined) {
      sessionStorage.setItem("token", token);
      dispatch(callVerifyToken());
    } else {
      window.location.href = `${assisto_qa_page}/verify-email`;
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LoadingPage />
    </ThemeProvider>
  );
};

export default HomePage;
