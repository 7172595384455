interface IRoute {
  component: React.ElementType;
  layout?: any;
}

const RouteComponent = (props: IRoute) => {
  const { component: Component, layout: Layout } = props;
  const getComponent = () => <Component />;

  return Layout ? <Layout>{getComponent()}</Layout> : getComponent();
};

export default RouteComponent;
