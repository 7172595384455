import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import {
  IStateInfoResponse,
  StateLocationToken,
} from "../thunks/stateLocationThunk";

export interface ICountryLocationProps {
  stateInfo: IStateInfoResponse[];
  loading: string;
}

const initialState: ICountryLocationProps = {
  stateInfo: [],
  loading: "",
};

const StateInfoSlice = createSlice({
  name: "stateInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<IStateInfoResponse[]>) => {
      state.stateInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(StateLocationToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(StateLocationToken.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.stateInfo = action.payload;
      })
      .addCase(StateLocationToken.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default StateInfoSlice.reducer;
export const { setStateInfo } = StateInfoSlice.actions;
export const stateData = (state: RootState) => state.getAllStatesList.stateInfo;
