import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface IEducationDegreeResponse {
  status_message: string;
  data: IEducationData[];
}

export interface IEducationData {
  id: string;
  education_degree_name: string;
}

export const EducationDegreeToken = createAsyncThunk(
  "education/educationInfo",
  async () => {
    const domain_name = "india";
    const EducationDegreeEndPoint = `job_portal/degrees?domain_name=${domain_name}`;
    try {
      const response = await Get<IEducationDegreeResponse, undefined>(
        EducationDegreeEndPoint,
        undefined,
        {},
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
