import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface ICityInfoResponse {
  id: string;
  name: string;
}

interface ICitylocation {
  stateId: string;
  countryId: string;
}

export const CityLocationToken = createAsyncThunk(
  "city/cityInfo",
  async (stateAndCountryData: ICitylocation) => {
    const CityLocationEndPoint = `http://openapis.nspenvision.com/location/?fields=id,name&countryId=${stateAndCountryData.countryId}&stateId=${stateAndCountryData.stateId}`;
    try {
      const response = await Get<ICityInfoResponse[], undefined>(
        CityLocationEndPoint,
        undefined
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
