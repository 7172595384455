import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { CurrencyTokenApi, ICurrencyResponse } from "../thunks/currencyThunk";

export interface ICurrencyProps {
  currencyInfo: ICurrencyResponse[];
  loading: string;
}

const initialState: ICurrencyProps = {
  currencyInfo: [],
  loading: "",
};

const CurrencyInfoSlice = createSlice({
  name: "currencyInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<ICurrencyResponse[]>) => {
      state.currencyInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CurrencyTokenApi.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(CurrencyTokenApi.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.currencyInfo = action.payload;
      })
      .addCase(CurrencyTokenApi.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default CurrencyInfoSlice.reducer;
export const { setStateInfo } = CurrencyInfoSlice.actions;
export const currencyData = (state: RootState) =>
  state.getAllCurrencyList.currencyInfo;
