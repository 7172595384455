import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { GetSkillThunk, ISkillResponse } from "../thunks/getSkillthunk";

export interface ISkillProps {
  getSkillInfo: ISkillResponse;
  loading: string;
}

const initialState: ISkillProps = {
  getSkillInfo: {
    status_message: "",
    data: [],
  },
  loading: "",
};

const GetSkillSlice = createSlice({
  name: "skillInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<ISkillResponse>) => {
      state.getSkillInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetSkillThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(GetSkillThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.getSkillInfo = action.payload;
      })
      .addCase(GetSkillThunk.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default GetSkillSlice.reducer;
export const { setStateInfo } = GetSkillSlice.actions;
export const skillData = (state: RootState) =>
  state.getAllSkillsList.getSkillInfo.data;
