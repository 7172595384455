import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import {
  EducationDegreeToken,
  IEducationDegreeResponse,
} from "../thunks/educationDegreeThunk";

export interface IeducationDegreeProps {
  educationInfo: IEducationDegreeResponse;
  loading: string;
}

const initialState: IeducationDegreeProps = {
  educationInfo: {
    status_message: "",
    data: [],
  },
  loading: "",
};

const EducationDegreeSlice = createSlice({
  name: "stateInfo",
  initialState,
  reducers: {
    setStateInfo: (state, action: PayloadAction<IEducationDegreeResponse>) => {
      state.educationInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(EducationDegreeToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(EducationDegreeToken.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.educationInfo = action.payload;
      })
      .addCase(EducationDegreeToken.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default EducationDegreeSlice.reducer;
export const { setStateInfo } = EducationDegreeSlice.actions;
export const certifiedDegreeData = (state: RootState) =>
  state.getAllEducationList.educationInfo.data;
