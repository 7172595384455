import { Grid, Stack, Typography } from "@mui/material";
import FileCard from "./FileCard";
import { DragEvent } from "react";
import { AssistoButton } from "../../../../widgets/Button";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import DragAndDropModule from "../../../../widgets/DragAndDropComponent";
import { MAX_LIMIT_FOR_UPLOAD_RESUME } from "../../../../utils/constants";

interface IFileUploadResume {
  handleUploadFileChange: (file: File[]) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  handleDrop: (e: DragEvent<HTMLDivElement>) => void;
  handleDrag: (
    e: DragEvent<HTMLDivElement> | DragEvent<HTMLFormElement>
  ) => void;
  dragActive: boolean;
  errorMessage: string;
  fileCardData: File[];
  handleCancel: () => void;
  handleRemoveFile: (index: number) => void;
  handleFileUpload: () => void;
}

function FileUploadResume(props: IFileUploadResume) {
  const {
    handleUploadFileChange,
    inputRef,
    handleDrag,
    handleDrop,
    dragActive,
    errorMessage,
    fileCardData,
    handleCancel,
    handleRemoveFile,
    handleFileUpload,
  } = props;
  const { t } = useTranslation(NAMESPACE.HOME);

  const bytesToMB = (bytes: number) => {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  };

  const getTotalFileCount = () =>
    (
      fileCardData.reduce((total, file) => total + file.size, 0) /
      1024 /
      1024
    ).toFixed(2);

  return (
    <Grid container>
      <Grid item lg={6} md={6} borderRight={"1px solid #000"}>
        <DragAndDropModule
          style={{ width: "22%" }}
          handleUploadFileChange={handleUploadFileChange}
          inputRef={inputRef}
          handleDrop={handleDrop}
          handleDrag={handleDrag}
          dragActive={dragActive}
          errorMessage={errorMessage}
          fileTypes={".pdf,.doc,.docx,.zip"}
        />
        <Stack ml={3} mt={1}>
          <Typography
            color={"var(--color-text-grey)"}
            fontSize={"var(--font-size-12)"}
          >
            {t("HOME_PAGE.UPLOAD_MODAL.TOTAL_FILES_COUNT")} :{" "}
            <span
              style={{
                color: "var(--color-primary)",
                fontWeight: "var(--font-weight-600)",
                fontSize: "var(--font-size-18)",
              }}
            >
              {fileCardData?.length}
            </span>
            /{MAX_LIMIT_FOR_UPLOAD_RESUME}
          </Typography>
          <Typography
            color={"var(--color-text-grey)"}
            fontSize={"var(--font-size-12)"}
          >
            {t("HOME_PAGE.UPLOAD_MODAL.TOTAL_FILE_SIZE")} :{" "}
            <span
              style={{
                color: "var(--color-primary)",
                fontWeight: "var(--font-weight-600)",
                fontSize: "var(--font-size-18)",
              }}
            >{`${getTotalFileCount()} MB`}</span>
          </Typography>
        </Stack>
      </Grid>
      <Grid item lg={6} md={6} mt={2} px={2}>
        <Grid
          className="pageScroll"
          sx={{ maxHeight: "380px", overflowY: "scroll" }}
        >
          {fileCardData !== null &&
            fileCardData !== undefined &&
            fileCardData.length > 0 &&
            fileCardData
              .slice()
              .reverse()
              .map((data, index) => (
                <FileCard
                  key={index}
                  fileName={data.name}
                  fileType={data.type}
                  fileSize={bytesToMB(data.size)}
                  index={index}
                  handleRemoveFile={handleRemoveFile}
                />
              ))}
        </Grid>
        {fileCardData?.length > 0 && (
          <Grid
            container
            direction={"row"}
            justifyContent={"end"}
            alignItems={"flex-end"}
            mt={2}
            gap={1}
            spacing={1}
          >
            <Grid item lg={4}>
              <AssistoButton
                buttonVarient="outlined"
                name={t("HOME_PAGE.UPLOAD_MODAL.CANCEL")}
                buttonClick={handleCancel}
              />
            </Grid>
            <Grid item lg={4}>
              <AssistoButton
                buttonVarient="contained"
                name={t("HOME_PAGE.UPLOAD_MODAL.UPLOAD")}
                buttonClick={handleFileUpload}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default FileUploadResume;
