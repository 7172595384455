import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { callAddSkill, IAddSkillsResponse } from "../thunks/skillsThunk";

export interface InitialStateDataProps {
  addedSkills: IAddSkillsResponse;
  loading: string;
}

const initialState: InitialStateDataProps = {
  addedSkills: {
    status_message: "",
    data: {
      message: "",
      skills_added: [""],
      subskills_added: [""],
    },
  },
  loading: "",
};

const addSkillInfoSlice = createSlice({
  name: "addedSkill",
  initialState,
  reducers: {
    setAddSkillInfo: (state, action: PayloadAction<IAddSkillsResponse>) => {
      state.addedSkills = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(callAddSkill.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(callAddSkill.fulfilled, (state, action:any) => {
        state.loading = "succeeded";
        state.addedSkills = action.payload;
      })
      .addCase(callAddSkill.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default addSkillInfoSlice.reducer;
export const { setAddSkillInfo } = addSkillInfoSlice.actions;
export const addedSkillInfoData = (state: RootState) => state.getAddSkillList;
