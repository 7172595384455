import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";

export interface IBreadCrumbItem {
  label: string;
  onClick?: () => void;
}

interface IBreadcrumbsProps {
  items: IBreadCrumbItem[];
}

const AssistoBreadcrumbs: React.FC<IBreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumbs
      sx={{ my: "30px" }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {items.map((item, index) => (
        <Link
          className={
            index === items.length - 1
              ? "active-breadcrumb"
              : "inactive-breadcrumb"
          }
          key={index}
          color="inherit"
          onClick={item.onClick}
          underline="none"
          data-testid={item.label}
        >
          <Typography
            variant="body1"
            style={{
              fontWeight:
                index === items.length - 1
                  ? "var(--font-weight-600)"
                  : "var(--font-weight-400)",
            }}
          >
            {item.label}
          </Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default AssistoBreadcrumbs;
