import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { getJobsListPage } from "../thunks/jobsPageListthunk";

export interface IJobsList {
  status_message: string;
  data: IData;
}

export interface IData {
  jobs_details_list: IJobsDetailsList[];
  page: number;
  pageSize: number;
  jobs_count: number;
}

export interface IJobsDetailsList {
  id: number;
  job_title: string;
  minimum_experience: number;
  maximum_experience: number;
  country: string;
  state: string;
  city: string;
  status: string;
  must_have_skills: any[];
  nice_to_have_skills: any[];
  matched_profiles: number;
  posting_type: string;
}

export interface IjobsListresponse {
  jobsData: IJobsList;
  loading: string;
}

const initialState: IjobsListresponse = {
  jobsData: {
    status_message: "",
    data: {
      jobs_details_list: [],
      page: 0,
      pageSize: 0,
      jobs_count: 0,
    },
  },
  loading: "",
};

export const jobsListSlice = createSlice({
  name: "jobsList",
  initialState,
  reducers: {
    setResumeList: (state, action: PayloadAction<IJobsList>) => {
      state.jobsData.status_message = action.payload.status_message;
      state.jobsData.data = action.payload.data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getJobsListPage.pending, (state) => {
        state.loading = "Pending";
      })
      .addCase(getJobsListPage.fulfilled, (state, action: any) => {
        state.loading = "Succeded";
        state.jobsData = action.payload;
      })
      .addCase(getJobsListPage.rejected, (state, action: any) => {
        state.loading = "Failed";
      });
  },
});

export const { setResumeList } = jobsListSlice.actions;
export const GetCompleteJobsListData = (state: RootState) =>
  state.getAlljobsPageList.jobsData.data.jobs_details_list;

export const GetTotalJobsData = (state: RootState) =>
  state.getAlljobsPageList.jobsData.data.jobs_count;
export default jobsListSlice.reducer;
