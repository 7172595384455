import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createJdThunk, IcreateJdResponse } from "../thunks/createJdThunk";
import { RootState } from "../store/store";

export interface IcreateJdFormdata {
  formData: IcreateJdResponse;
  loading: string;
}

const initialState: IcreateJdFormdata = {
  formData: {
    status_message: "",
    data: {
      id: 0,
      create_status: "",
    },
  },
  loading: "",
};

export const createJdSlice = createSlice({
  name: "createJd",
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<IcreateJdResponse>) => {
      state.formData = action.payload;
    },
    resetCreateJdState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createJdThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(createJdThunk.fulfilled, (state, action: any) => {
        state.loading = "succeeded";
        action.formData = action.payload.data;
      })
      .addCase(createJdThunk.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default createJdSlice.reducer;
export const { setState, resetCreateJdState } = createJdSlice.actions;
export const createJdInfo = (state: RootState) => state.createJdFormData;
export const createJdApiLoading = (state: RootState) =>
  state.createJdFormData.loading;
