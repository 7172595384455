import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface IDatePicker {
  value: Dayjs | null;
  label: string;
  id: string;
  handleDatePickChange: (date: Dayjs | null, id: string) => void;
  minDate?: Dayjs | undefined;
  error?: boolean;
  helperText?: string;
}

export default function AssistoDatePicker(props: IDatePicker) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          sx={{ width: "100%" }}
          value={props.value}
          label={props.label}
          format="DD-MM-YYYY"
          onChange={(newValue) =>
            props.handleDatePickChange(newValue, props.id)
          }
          slotProps={{
            textField: {
              error: props.error,
              helperText: props.helperText,
            },
          }}
          minDate={props.minDate}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
