import React, { DragEvent } from "react";
import { ThemeProvider } from "styled-components";
import theme from "../../../../widgets/Theme/theme";
import FileUploadResume from "./FileUploadResume";
import DragAndDropModule from "../../../../widgets/DragAndDropComponent";

interface IdragDropComponent {
  handleUploadFileChange: (file: File[]) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  handleDrop: (e: DragEvent<HTMLDivElement>) => void;
  handleDrag: (
    e: DragEvent<HTMLDivElement> | DragEvent<HTMLFormElement>
  ) => void;
  dragActive: boolean;
  errorMessage: string;
  fileCardData: File[];
  handleCancel: () => void;
  handleRemoveFile: (id: number) => void;
  handleFileUpload: () => void;
  onProgress: number;
}

export const DragDropComponent = (props: IdragDropComponent) => {
  const {
    handleUploadFileChange,
    inputRef,
    handleDrag,
    handleDrop,
    dragActive,
    errorMessage,
    fileCardData,
    handleCancel,
    handleRemoveFile,
    handleFileUpload,
  } = props;

  return (
    <>
      <ThemeProvider theme={theme}>
        {fileCardData.length === 0 && (
          <DragAndDropModule
            style={{ width: "12%" }}
            handleUploadFileChange={handleUploadFileChange}
            inputRef={inputRef}
            handleDrop={handleDrop}
            handleDrag={handleDrag}
            dragActive={dragActive}
            errorMessage={errorMessage}
            fileTypes={".pdf,.doc,.docx,.zip"}
          />
        )}
        {fileCardData.length > 0 && (
          <FileUploadResume
            handleUploadFileChange={handleUploadFileChange}
            inputRef={inputRef}
            handleDrop={handleDrop}
            handleDrag={handleDrag}
            dragActive={dragActive}
            errorMessage={errorMessage}
            fileCardData={fileCardData}
            handleCancel={handleCancel}
            handleRemoveFile={handleRemoveFile}
            handleFileUpload={handleFileUpload}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default DragDropComponent;
