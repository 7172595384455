import { Grid, Input, Typography } from "@mui/material";
import React, { DragEvent } from "react";
import FolderGif from "../../assets/images/file.gif";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";

interface IstyleType {
  width: string;
}
interface IDragAndDropModule {
  style: IstyleType;
  handleUploadFileChange: (file: File[]) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  handleDrop: (e: DragEvent<HTMLDivElement>) => void;
  handleDrag: (
    e: DragEvent<HTMLDivElement> | DragEvent<HTMLFormElement>
  ) => void;
  dragActive: boolean;
  errorMessage: string;
  fileTypes: string;
}

function DragAndDropModule(props: IDragAndDropModule) {
  const {
    style,
    handleUploadFileChange,
    inputRef,
    handleDrag,
    handleDrop,
    dragActive,
    errorMessage,
    fileTypes,
  } = props;
  const { t } = useTranslation(NAMESPACE.HOME);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files !== null) {
      const filesArray = Array.from(e.target.files);
      handleUploadFileChange(filesArray);
    }
  };

  return (
    <>
      <Grid container sx={{ p: 2.8 }} data-testid="drag-drop-module">
        <Grid item xs={12}>
          <form
            style={{ margin: "0 0 25px 0" }}
            className="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
            data-testid="form-file-upload"
          >
            {errorMessage && (
              <Typography
                fontWeight={"var(--font-weight-400)"}
                variant="h6"
                textAlign={"right"}
                color="var(--color-dark-red)"
              >
                {errorMessage}
              </Typography>
            )}
            <Input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              inputProps={{
                accept: fileTypes,
                multiple: true,
                "data-testid": "input-file-upload",
              }}
              onChange={handleFileUpload}
            />
            <label
              htmlFor="input-file-upload"
              className={
                dragActive
                  ? "drag-active label-file-upload"
                  : "label-file-upload"
              }
            >
              <div>
                <img style={style} src={FolderGif} alt="foldergif" />
                <Typography variant="h5" py={0.5}>
                  {t("HOME_PAGE.UPLOAD_MODAL.DRAG_AND_DROP_OR")}{" "}
                  <span className="browse-files">
                    {t("HOME_PAGE.UPLOAD_MODAL.BROWSE")}
                  </span>{" "}
                  {t("HOME_PAGE.UPLOAD_MODAL.YOUR_FILES")}
                </Typography>
                <Typography
                  fontWeight={"var(--font-weight-400)"}
                  variant="h5"
                  color={"var(--color-text-grey)"}
                  mt={1}
                  mb={2}
                >
                  {t("HOME_PAGE.UPLOAD_MODAL.SUPPORTED_FILES")}
                  <span className="file-format">
                    : {t("HOME_PAGE.UPLOAD_MODAL.DOC_DOCX_PDF_AND_ZIP")}
                  </span>
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight={"var(--font-weight-400)"}
                  py={1}
                  color={"var(--color-text-grey)"}
                  px={2}
                >
                  <span className="red-text">
                    {t("HOME_PAGE.UPLOAD_MODAL.NOTE")}{" "}
                  </span>
                  : {t("HOME_PAGE.UPLOAD_MODAL.EACH_FILE_SIZE_LIMIT_IS")}{" "}
                  <span className="file-format blue-text">
                    {t("HOME_PAGE.UPLOAD_MODAL.5MB")}{" "}
                  </span>
                  & {t("HOME_PAGE.UPLOAD_MODAL.ZIP_FILE_SIZE_IS")}{" "}
                  <span className="file-format blue-text">
                    {t("HOME_PAGE.UPLOAD_MODAL.500MB")}
                  </span>
                </Typography>
              </div>
            </label>
            {dragActive && (
              <div
                className="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default DragAndDropModule;
