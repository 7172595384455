import { ReactNode } from "react";
import Header from "../Header";
import { Grid } from "@mui/material";
import Menubar from "../Menubar";

interface ILayout {
  children: ReactNode;
}

const Layout = (props: ILayout) => {
  const { children } = props;

  return (
    <Grid container>
      {/* Header */}
      <Grid item xs={12}>
        <Header />
      </Grid>

      {/* Menubar */}
      <Grid item   md={2} lg={1}>
        <Menubar />
      </Grid>

      {/* Main Content */}
      <Grid item md={10} lg={11}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Layout;
