import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get } from "../utils/apiServices";

export interface IJobsList {
  status_message: string;
  data: IData;
}

export interface IData {
  jobs_details_list: IJobsDetailsList[];
  page: number;
  pageSize: number;
  jobs_count: number;
}

export interface IJobsDetailsList {
  id: number;
  job_title: string;
  minimum_experience: number;
  maximum_experience: number;
  country: string;
  state: string;
  city: string;
  status: string;
  must_have_skills: any[];
  nice_to_have_skills: any[];
  matched_profiles: number;
  posting_type: string;
}
export interface IJobsPageRequest {
  page: number;
  pageSize: number;
}

const getJobsPageListEndPoint =
  "/job_description/all?page=:page&pageSize=:page_size";

export const getJobsListPage = createAsyncThunk(
  "resumesPage/getResumesPage",
  async (data: IJobsPageRequest, thunkAPI) => {
    const endPoint = getJobsPageListEndPoint
      .replace(":page", data.page.toString())
      .replace(":page_size", data.pageSize.toString());
    try {
      const response = await Get<IJobsList, IJobsPageRequest>(
        endPoint,
        undefined,
        {},
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
