import React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface PaginationComponentProps {
  onPageNumberClick?: (clickedPageNumber: number) => void;
  onRowsPerPageChange?: (value: number) => void;
  totalItems: number;
  currentPageNumber: number;
  rowsPerPage?: number | string;
  rowsPerPageList?: any[];
}

const PaginationComponent = (props: PaginationComponentProps) => {
  const {
    onPageNumberClick,
    onRowsPerPageChange,
    totalItems = 50,
    currentPageNumber = 1,
    rowsPerPage = 10,
    rowsPerPageList = [10, 25, "All"],
  } = props;
  const [selectedRowValue, setSelectedRowValue] = React.useState(rowsPerPage);
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onPageNumberClick?.(value);
  };

  const handleRowsPerPageChange = (event: any) => {
    const value =
      event.target.value === "All" ? "All" : parseInt(event.target.value);
    onRowsPerPageChange?.(value === "All" ? totalItems : Number(value));
    setSelectedRowValue(value);
    onPageNumberClick?.(1);
  };
  const totalPages =
    selectedRowValue === "All"
      ? 1
      : Math.ceil(totalItems / Number(selectedRowValue));
  return (
    <div className="Assisto-pagination">
      <Box className="Assisto-pagination-container">
        <Box display="flex" alignItems="center" mr={2}>
          <Typography variant="body2">Rows per page:</Typography>
          <Select
            value={selectedRowValue}
            onChange={handleRowsPerPageChange}
            variant="standard"
            disableUnderline
            style={{ marginLeft: 8, marginRight: 8 }}
          >
            {rowsPerPageList &&
              rowsPerPageList.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </Select>
        </Box>

        <Pagination
          count={totalPages}
          variant="outlined"
          page={currentPageNumber}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              className="Assisto-pagination-item"
              style={{
                color: item.selected ? "white" : "black",
                backgroundColor: item.selected ? "black" : "white",
              }}
            />
          )}
        />
      </Box>
    </div>
  );
};

export default PaginationComponent;
